import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Browser } from '@capacitor/browser';
import { AlertController, Platform } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { DeepCopy, ToLocalizedValuePipe, User, UserDataService, UserStructure, toBoolean } from 'configurator-shared';
import { ConfiguratorAuthenticationService } from '../configurator-authentication.service';

@Component({
    selector: 'app-privacy-list',
    templateUrl: './privacy-list.component.html',
    styleUrls: ['./privacy-list.component.scss'],
    providers: [ToLocalizedValuePipe],
})
export class PrivacyListComponent {
    @Output() privacySaved = new EventEmitter<void>();
    @Input() useToggle = false;

    privacyForm: FormGroup = new FormGroup({});

    privacyFields: any[] = [];
    userPrivacyValues: { [key: string]: boolean } = {};
    authenticatedUserData: User;

    constructor(
        private authService: ConfiguratorAuthenticationService,
        private userData: UserDataService,
        private platform: Platform,
        private alertCtrl: AlertController,
        private toLocalizedValuePipe: ToLocalizedValuePipe,
        private logger: NGXLogger,
    ) {
        this.authenticatedUserData = DeepCopy(this.userData.getAuthenticatedUserInstant());
        const structure = this.userData.getAuthenticatedUserStructureInstant();

        if (structure) {
            this.preparePrivacyFields(structure);
        } else {
            this.logger.debug('redownloading user structure');
            this.authService.getUserStructure().subscribe((structure) => {
                this.preparePrivacyFields(structure);
            });
        }
    }

    private preparePrivacyFields(structure: UserStructure) {
        for (const sfield of structure.structureFields) {
            if (sfield.name.includes('privacy') || sfield.type == 'privacy') {
                sfield.value = this.preparePrivacyText(sfield.value as string);
                const currentValue: string | undefined = this.authenticatedUserData[sfield.name];
                this.userPrivacyValues = { ...this.userPrivacyValues, [sfield.name]: toBoolean(currentValue) };
                const validators = new Array<ValidatorFn>();
                if (sfield.extra && JSON.parse(sfield.extra)?.required) {
                    validators.push(Validators.requiredTrue);
                }
                this.privacyForm.addControl(sfield.name, new FormControl('', { validators, nonNullable: true }));
                this.privacyFields.push(sfield);
            }
        }
    }

    preparePrivacyText(privacyLabel: string): string[] {
        if (privacyLabel && privacyLabel != '') {
            privacyLabel = this.toLocalizedValuePipe.transform(JSON.parse(privacyLabel));
            const linkStartPosition = privacyLabel.indexOf('<u>');
            const linkEndPosition = privacyLabel.indexOf('</u>');
            if (linkStartPosition !== -1 && linkEndPosition !== -1) {
                return [
                    privacyLabel.substring(0, linkStartPosition),
                    privacyLabel.substring(linkStartPosition, linkEndPosition),
                    privacyLabel.substring(linkEndPosition),
                ];
            } else {
                return [privacyLabel];
            }
        }
        return [''];
    }

    submitPrivacy() {
        Object.entries(this.userPrivacyValues).forEach(([key, value]) => {
            value = this.privacyForm.get(key)?.value;
        });

        this.authenticatedUserData = { ...this.authenticatedUserData, ...this.userPrivacyValues };

        this.authService.updateUser({ ...this.authenticatedUserData }).subscribe({
            next: (success) => {
                this.privacySaved.emit();
            },
        });
    }

    openPrivacy(text: string) {
        try {
            const parsedText = JSON.parse(text);
            if (Object.keys(parsedText).includes('url')) {
                const url: string = parsedText['url'];
                Browser.open({ url });
            } else {
                this.alertCtrl
                    .create({
                        header: 'Privacy',
                        message: this.toLocalizedValuePipe.transform(parsedText),
                        cssClass: 'privacy-alert',
                        buttons: ['OK'],
                        mode: this.platform.is('ios') ? 'ios' : 'md',
                    })
                    .then((alert) => alert.present());
            }
        } catch {
            this.logger.error("Can't parse privacy extra");
        }
    }
}
