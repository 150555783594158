import { Inject, Injectable, OnDestroy } from '@angular/core';
import { OAuth2Client } from '@byteowls/capacitor-oauth2';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, lastValueFrom, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import {
    AuthState,
    AuthenticationToken,
    DataleanBaseApiService,
    EnvironmentConfiguration,
    Parts,
    User,
    UserDataService,
    UserStructure,
    getAuthenticatedUserData,
} from 'configurator-shared';

import { setAuthError } from './store/auth.actions';
import { authCapConfig } from 'src/app/config/auth-cap.config';

@Injectable({
    providedIn: 'root',
})
export class ConfiguratorAuthenticationService implements OnDestroy {
    readonly userGetParts = [Parts.VALUES, Parts.SCORE, Parts.COMMUNITY];
    private _accessToken: string | null;
    private _refreshToken: string | null;
    private _idToken: string | null;

    isDoneLoading$ = new BehaviorSubject(true);
    isAuthenticated$ = new BehaviorSubject(false);
    isDestroyed$ = new Subject();

    constructor(
        @Inject('env') private environmentSettings: EnvironmentConfiguration,
        private baseApi: DataleanBaseApiService,
        private store: Store<AuthState>,
        private userDataService: UserDataService,
        private navCtrl: NavController,
    ) {}

    ngOnDestroy(): void {
        this.isDestroyed$.next(true);
        this.isDestroyed$.complete();
        this.isAuthenticated$.complete();
        this.isDoneLoading$.complete();
    }

    private setToken(token: AuthenticationToken) {
        this.userDataService.setAuthenticationToken(token);
        const userUUID = token.getPayloadParameter('userUUID');
        const savedUser = this.userDataService.getAuthenticatedUserInstant();
        // console.log('userUUID', userUUID);
        // console.log('savedUser', savedUser);
        if (!savedUser || savedUser.uuid !== userUUID) {
            return lastValueFrom(this.baseApi.getEntity<User>(this.environmentSettings.usersUrl, userUUID, this.userGetParts));
        }
        return savedUser;
    }

    private async saveResponse(
        response: { access_token_response: { access_token: string; refresh_token: string; id_token: string } },
        redirect?: string[],
    ) {
        const {
            access_token_response: { access_token, refresh_token, id_token },
        } = response;
        if (access_token) {
            // console.log('access_token', access_token);
            this._accessToken = access_token;
            localStorage.setItem('accessToken', this._accessToken);
        }
        if (refresh_token) {
            // console.log('refresh_token', refresh_token);
            this._refreshToken = refresh_token;
            localStorage.setItem('refreshToken', this._refreshToken);
        }
        if (id_token) {
            this._idToken = id_token;
            localStorage.setItem('idToken', this._idToken);
        }
        try {
            const token = new AuthenticationToken(this.idToken);
            const user = await this.setToken(token);
            // console.log('user', user);
            await this.userDataService.setAuthenticatedUser(user);
            this.isDoneLoading$.next(true);
            this.isAuthenticated$.next(true);
            if (redirect) {
                return await this.navCtrl.navigateRoot(redirect);
            }
            return true;
        } catch (authError: any) {
            this.store.dispatch(setAuthError({ authError }));
        }
        return false;
    }

    public async requestAccess(redirect?: string[]): Promise<boolean> {
        if (!this.isDoneLoading$.value) return false;
        if ((this.refreshToken?.length || this.accessToken?.length) && this.hasValidToken() && this.tokenIsExpiring()) {
            try {
                // console.log('refreshToken', this.refreshToken);
                // console.log('accessToken', this.accessToken);
                /**
                 * This part is to implement
                 * in pwa's not working refresh
                 */
                const response = await OAuth2Client.refreshToken({
                    refreshToken: (this.refreshToken ?? this.accessToken)!,
                    appId: authCapConfig.appId!,
                    accessTokenEndpoint: authCapConfig.accessTokenEndpoint!,
                    scope: authCapConfig.scope,
                });
                // console.log('response', response);
                return await this.saveResponse(response, redirect);
            } catch (err) {
                console.error(err);
            }
        }
        if (this.idToken?.length) {
            // console.log('idToken', this.idToken);
            try {
                const token = new AuthenticationToken(this.idToken);
                const user = await this.setToken(token);
                // console.log('user', user);
                await this.userDataService.setAuthenticatedUser(user);
                this.isAuthenticated$.next(true);
                return true;
            } catch (err) {
                console.error(err);
            }
        }

        this.isDoneLoading$.next(false);
        try {
            const response = await OAuth2Client.authenticate(authCapConfig);
            // console.log('response', response);

            return await this.saveResponse(response, redirect);

            // go to backend
        } catch (reason) {
            console.error('OAuth rejected', reason);
            await this.logout();
        }
        this.isDoneLoading$.next(true);
        this.isAuthenticated$.next(false);
        return false;
    }

    getUserData(userUUID: string): Observable<User> {
        return this.baseApi.getEntity<User>(this.environmentSettings.usersUrl, userUUID, this.userGetParts).pipe(
            map((data) => {
                this.userDataService.setAuthenticatedUser(data);
                return data;
            }),
        );
    }

    getUserStructure(): Observable<UserStructure> {
        const parts = [Parts.STRUCTURE_FIELDS];
        return this.userDataService.getAuthenticatedUserObs().pipe(
            take(1),
            switchMap((user) => {
                if (user) {
                    return this.baseApi.getEntity<UserStructure>(this.environmentSettings.userStructureUrl, user.structureUUID, parts).pipe(
                        map((data) => {
                            this.userDataService.setAuthenticatedUserStructure(data);
                            return data;
                        }),
                    );
                }
                return throwError(() => new Error('no user'));
            }),
        );
    }

    getUserForPasswordRecovery(username: string): Observable<User> {
        const params = {
            query: username,
            searchFields: 'username',
        };
        return this.baseApi.getEntities(this.environmentSettings.usersUrl!, params, [Parts.EMPTY]);
    }

    updateUser(updatedUser: User): Observable<User> {
        return this.baseApi.updateEntity<User>(this.environmentSettings.usersUrl!, updatedUser, this.userGetParts).pipe(
            map(() => {
                //il nuovo datalean potrebbe non ritornare il json dopo un update
                this.userDataService.setAuthenticatedUser(updatedUser);
                return updatedUser;
            }),
        );
    }

    postUserUUIDForPasswordRecovery(userUUID: string) {
        const data = { uuid: userUUID };
        return this.baseApi.createEntity(this.environmentSettings.usersUrl + userUUID + '/reset-password', data, [Parts.EMPTY]);
    }

    userNeedPrivacyValidation(): Observable<boolean> {
        return this.getUserStructure().pipe(
            map((structure: UserStructure) => {
                return !!structure.structureFields.find((sfield) => {
                    if (sfield.name.includes('privacy') || sfield.type == 'privacy') {
                        if (sfield.extra && JSON.parse(sfield.extra)?.required) {
                            const user = this.userDataService.getAuthenticatedUserInstant();
                            const privacyValue: string | boolean = user?.[sfield.name];
                            if (privacyValue !== 'true' && privacyValue !== true) {
                                return true;
                            }
                        }
                    }
                    return false;
                });
            }),
            catchError(() => of(false)),
        );
    }

    userMustChangePassword(): Observable<boolean> {
        return this.store.select(getAuthenticatedUserData).pipe(
            map((userData: User) => userData?.status === 'CHANGE_PASSWORD'),
            take(1),
        );
    }

    hasValidToken(): boolean {
        return !!this.idToken && this.userDataService.authenticationTokenIsValid();
    }

    tokenIsExpiring(): boolean {
        return this.userDataService.authenticationTokenIsExpiring();
    }

    navigateToLoginPage() {
        this.navCtrl.navigateRoot(['/']);
    }

    public async logout() {
        try {
            await OAuth2Client.logout(authCapConfig);
        } catch (err) {
            console.error(err);
        }
        await this.userDataService.clearDatabase();
        this.clearStorage();
        this.isAuthenticated$.next(false);
        this.navigateToLoginPage();
    }

    private clearStorage() {
        this._accessToken = null;
        this._refreshToken = null;
        this._idToken = null;
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('idToken');
        localStorage.clear();
    }

    get accessToken() {
        if (!this._accessToken?.length) {
            this._accessToken = localStorage.getItem('accessToken');
        }

        return this._accessToken;
    }

    get refreshToken() {
        if (!this._refreshToken?.length) {
            this._refreshToken = localStorage.getItem('refreshToken');
        }

        return this._refreshToken;
    }

    get idToken() {
        return this._idToken ?? localStorage.getItem('idToken');
    }
}
