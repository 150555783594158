/**
 * Libreria di funzioni utili
 */

import { Capacitor } from '@capacitor/core';
import moment from 'moment';
import { ISO_8601 } from 'moment';
import { Observable, Subject } from 'rxjs';
import { EnvironmentConfiguration } from './interfaces';
import { RemoteFileProperties } from './interfaces/remote-file-properties';
import { DateRange } from './models/DateRange';

export const isEmpty = (array: unknown) => {
  return !Array.isArray(array) || !array.length;
};

export const toBoolean = (valueStr?: string) => {
  switch (valueStr) {
    case 'true':
    case 'yes':
    case '1':
    case 'on':
      return true;
    case 'false':
    case 'no':
    case '0':
    case 'off':
      return false;
    default:
      return !!valueStr;
  }
};

/** Vengono impostati a true i check che passano la validazione */
export interface PasswordValidatorResultSet {
  LENGTH: boolean;
  LOWER: boolean;
  UPPER: boolean;
  NUMBER: boolean;
  SPECIAL: boolean;
}

/** Risultato della validazione di una password */
export class PasswordValidationResult {
  public static readonly PossibleChecks: (keyof PasswordValidatorResultSet)[] = ['LENGTH', 'LOWER', 'UPPER', 'NUMBER', 'SPECIAL'];

  constructor(private checks: PasswordValidatorResultSet) {}

  public isInvalid() {
    return Object.entries(this.checks).some(([key, value]) => value === false);
  }
  public isValid() {
    return !this.isInvalid();
  }

  public getErrors(): (keyof PasswordValidatorResultSet)[] {
    return Object.entries(this.checks)
      .filter(([key, value]) => value === false)
      .map(([key]) => key as keyof PasswordValidatorResultSet);
  }

  public hasError(name: keyof PasswordValidatorResultSet) {
    return Object.entries(this.checks)
      .find(([key]) => key === name)
      ?.map(([key, value]) => value as boolean);
  }
}

export const validatePassword = (password: string): PasswordValidationResult => {
  password = password || '';

  const lengthCheck = password.length >= 8;
  const lowerCaseLetter = /[a-z]/.test(password);
  const upperCaseLetter = /[A-Z]/.test(password);
  const numberCheck = /[0-9]/.test(password);
  const special = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password);
  return new PasswordValidationResult({
    LENGTH: lengthCheck,
    LOWER: lowerCaseLetter,
    UPPER: upperCaseLetter,
    NUMBER: numberCheck,
    SPECIAL: special,
  });
};

export class DateUtil {
  private static defaultLocale = 'it-IT';
  static readonly DATE_RANGE_STRING_FORMAT: string = 'DD/MM/YYYY';
  static readonly COMPACT_ISO_FORMAT: string = 'YYYYMMDD[T]HHmmssZZ';
  static readonly DATE_ONLY_FORMAT: string = 'YYYY-MM-DD';

  static today(format: string) {
    return moment().format(format);
  }

  static now(format: string, locale: string = this.defaultLocale) {
    return moment().locale(locale).format(format);
  }

  static subtractFrom(
    sourceISOString: string,
    amount: moment.DurationInputArg1,
    unitOfTime: moment.DurationInputArg2,
    format: string,
    locale: string = this.defaultLocale
  ) {
    const sourceMoment = moment(sourceISOString).locale(locale);
    return sourceMoment.subtract(amount, unitOfTime).format(format);
  }

  static roundToStartOfDay(dateISOString: string, format: string, locale: string = this.defaultLocale) {
    return moment(dateISOString).locale(locale).startOf('day').format(format);
  }

  static roundToEndOfDay(dateISOString: string, format: string, locale: string = this.defaultLocale) {
    return moment(dateISOString).locale(locale).endOf('day').format(format);
  }

  static isTodayDate(isoStringDate: string) {
    const dateParam = moment(isoStringDate);
    const today = moment();
    return dateParam.diff(today, 'day') == 0;
  }

  static convertISOStringToDate(isoString: string) {
    return moment(isoString, ISO_8601).toDate();
  }

  static convertDateRangeToString(dateRange: DateRange) {
    //TODO
    const fromLabelParam = /*this.smartTranslateService.translate(*/ 'FROM'; //);
    const toLabelParam = /*this.smartTranslateService.translate(*/ 'TO'; //);

    const fromLabel = fromLabelParam ? fromLabelParam.toLowerCase() + ':' : '';
    const toLabel = toLabelParam ? ' ' + toLabelParam.toLowerCase() + ':' : ' - ';
    return (
      fromLabel +
      this.formatISOString(dateRange.from, this.DATE_RANGE_STRING_FORMAT) +
      toLabel +
      this.formatISOString(dateRange.to, this.DATE_RANGE_STRING_FORMAT)
    );
  }

  static formatISOString(isoString: string, format: string, locale: string = this.defaultLocale) {
    return moment(isoString).locale(locale).format(format);
  }
}

const formatFileSize = (size: number) => {
  let fileSize = 0;
  let sizeKeyWord = '';

  if (!size) return undefined;

  if (size < 1024) {
    fileSize = size;
    sizeKeyWord = 'b';
  } else if (size < 1048576) {
    fileSize = Math.round((size * 100) / 1024) / 100;
    sizeKeyWord = 'Kb';
  } else if (size < 1073741824) {
    fileSize = Math.round((size * 100) / 1048576) / 100;
    sizeKeyWord = 'MB';
  } else if (size < 1099511627776) {
    fileSize = Math.round((size * 100) / 1073741824) / 100;
    sizeKeyWord = 'GB';
  } else {
    fileSize = Math.round((size * 100) / 1099511627776) / 100;
    sizeKeyWord = 'TB';
  }

  return `${fileSize}${sizeKeyWord}`;
};

export const extractRemoteFileProperties = (url: string): Observable<RemoteFileProperties> => {
  const subject = new Subject<RemoteFileProperties>();

  const xhr = new XMLHttpRequest();
  xhr.open('HEAD', url, true);

  xhr.onload = function () {
    const fileSize = +(xhr.getResponseHeader('Content-Length') ?? 0);
    const fileMime = xhr.getResponseHeader('Content-Type') ?? undefined;

    subject.next({
      size: formatFileSize(fileSize),
      mime: fileMime,
    });
  };
  xhr.onerror = function () {
    subject.next({
      size: '',
      mime: '',
    });
  };

  xhr.send();
  return subject.asObservable();
};

export const DeepCopy = (obj: unknown) => {
  try {
    return JSON.parse(JSON.stringify(obj));
  } catch (e) {
    console.error('Deep copy failed');
  }
  return {};
};

export const getFullURL = (relativeUrl: string, env: EnvironmentConfiguration) => {
  const baseOrigin = Capacitor.isNativePlatform() ? env.appId + '://' : window.location.origin + '/';

  if (relativeUrl.startsWith('/')) relativeUrl = relativeUrl.substring(1);

  return baseOrigin + relativeUrl;
};
