export interface ContestRequest {
  action: string;
  extra?: string;
  date?: Date;
}

export const CONTEST_TAG = 'contest';

//TODO typing
export interface ContestState {
  requests: Array<ContestRequest>;
  errorRequests: Array<ContestRequest>;
}
