/**
 * File di esportazione unificata delle interfacce
 */
export * from './interfaces/FCM-registration-data';
export * from './interfaces/environment-configuration';
export * from './interfaces/localization-configuration';
export * from './interfaces/privacy-configuration';
export * from './interfaces/remote-file-properties';
export * from './interfaces/DescriptionCardGalleryImage';
export * from './interfaces/user';
export * from './interfaces/Feedback';
export * from './interfaces/NotificationData';
export * from './interfaces/unknown-object';
export * from './interfaces/FeatureValue';
export * from './interfaces/Communication';
export * from './interfaces/Email';
export * from './interfaces/Phone';
export * from './interfaces/cockpit-communication';
export * from './interfaces/Asset';
export * from './interfaces/Attachment';
export * from './interfaces/Product';
export * from './interfaces/related-position';
export * from './enums/parts.enum';
