import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, defer, map, Observable, of } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { AuthenticationToken, ContestActionType, KEY, TableKey } from '../models';
import { User, UserStructure } from '../interfaces';
import { AuthState, getAuthenticatedUserData, insertRequest, setAuthToken, setUserData } from '../store';
import { PersistedDataManagerService } from './persisted-data-manager.service';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  private authenticationToken: AuthenticationToken | undefined;
  private authenticatedUser: User | undefined;
  private authenticatedUserStructure: UserStructure | undefined;

  constructor(
    private store: Store<AuthState>,
    private localDataManager: PersistedDataManagerService,
    private logger: NGXLogger,
    private localData: PersistedDataManagerService
  ) {}

  async init(): Promise<boolean> {
    const szUser = await this.localDataManager.getRow<User>(TableKey.MAIN, 'authenticatedUser');
    // console.log('szUser', szUser);
    if (szUser) {
      this.logger.info('trovato utente nel db');
      await this.setAuthenticatedUser(szUser, false);
    }
    const szToken = await this.localDataManager.getRow<{ token: string; expirationTimeStamp: string }>(
      TableKey.MAIN,
      'authenticationToken'
    );
    // console.log('szToken', szToken);
    if (szToken) {
      this.logger.info('trovato token nel db');
      const { token, expirationTimeStamp } = szToken;
      this.setAuthenticationToken(new AuthenticationToken(token, expirationTimeStamp), false);
    }
    return true;
  }

  getUserUUID(): string | undefined {
    return this.authenticatedUser?.uuid;
  }

  async setAuthenticatedUser(user?: User, dbSave = true) {
    this.authenticatedUser = user;
    this.logger.info('set utente sul store');
    this.store.dispatch(setUserData({ userData: this.authenticatedUser }));
    if (dbSave) {
      this.logger.info('set utente sul db');
      await this.localDataManager.setRow(TableKey.MAIN, 'authenticatedUser', this.authenticatedUser);
    }
  }

  setAuthenticatedUserStructure(structure: UserStructure) {
    this.authenticatedUserStructure = structure;
  }

  getAuthenticatedUserStructureInstant() {
    return this.authenticatedUserStructure;
  }

  getAuthenticatedUserObs(): Observable<User | undefined> {
    return this.store.select(getAuthenticatedUserData);
  }

  getAuthenticatedUserInstant(): User | undefined {
    // console.log('getAuthenticatedUserInstant', this.authenticatedUser);
    return this.authenticatedUser;
  }

  setAuthenticationToken(token?: AuthenticationToken, dbSave = true): void {
    if (token && !this.authenticationToken) {
      // first login
      this.store.dispatch(insertRequest({ request: { action: ContestActionType.LOGIN } }));
    }
    this.authenticationToken = token;
    this.logger.info('set token sul store');
    this.store.dispatch(setAuthToken({ authenticationToken: this.authenticationToken }));
    if (dbSave) {
      this.logger.info('set token sul db');
      this.localDataManager.setRow(TableKey.MAIN, 'authenticationToken', {
        token: this.authenticationToken?.token,
        expirationTimeStamp: this.authenticationToken?.expirationTimeStamp,
      });
    }
  }

  getAuthenticationTokenInstant(): AuthenticationToken | undefined {
    return this.authenticationToken;
  }

  /** Indica se è la prima volta che l'utente corrente esegue l'accesso */
  isFirstAcces() {
    return defer(() => this.localData.getRow<boolean>(TableKey.MAIN, KEY.hasDoneFirstAccess)).pipe(
      map((alreadyLogged) => !alreadyLogged),
      catchError(() => of(true))
    );
  }

  /** Imposta come effettuato il primo accesso. E' anche possibile resettarlo usando come parametro 'false' */
  setFirstAccess(value = true) {
    return defer(() => this.localData.setRow(TableKey.MAIN, KEY.hasDoneFirstAccess, value)).pipe(
      catchError((error) => {
        // error here is trivial, can continue
        this.logger.error('Error saving first access flag: ', error);
        return of(void 0);
      })
    );
  }

  authenticationTokenIsValid(): boolean {
    return this.authenticationToken?.isValid() ?? false;
  }

  authenticationTokenIsExpiring(): boolean {
    return this.authenticationToken?.isExpiring() ?? false;
  }

  async clearDatabase() {
    await this.setAuthenticatedUser(undefined);
    this.setAuthenticationToken(undefined);
    return await this.localDataManager.clearDB();
  }
}
