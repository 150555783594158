import { Subject } from 'rxjs';
import { OverlayRef } from '@angular/cdk/overlay';
import { Type } from '@angular/core';

export interface ModalCloseEvent<R> {
  type: 'backdropClick' | 'close';
  data: R;
}

// R = Response Data Type, T = Data passed to Modal Type
export class ModalRef<R = any, T = any> {
  onDidClose = new Subject<ModalCloseEvent<R>>();

  constructor(public modal: OverlayRef, public content: Type<any>, public data: T, public canExitThroughBackdropClick) {
    if (canExitThroughBackdropClick) {
      modal.backdropClick().subscribe(() => this._close('backdropClick'));
    }
  }

  close(data?: R) {
    this._close('close', data as R);
  }

  private _close(type: 'backdropClick' | 'close', data: R = null as R) {
    this.modal.dispose();
    this.onDidClose.next({
      type,
      data,
    });

    this.onDidClose.complete();
  }
}
