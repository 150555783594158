export class NotificationToken {
  constructor(private _fcmToken: string, private _deviceType: string) {}

  get fcmToken() {
    return this._fcmToken;
  }

  get deviceType() {
    return this._deviceType;
  }
}
