import { Action, createReducer, on } from '@ngrx/store';
import { INGXLoggerMetadata } from 'ngx-logger';
import { SmartMenuButtonItem } from '../../models';
import {
  setWheelMenuButtons,
  addLog,
  setLogs,
  setMenuLeftButton,
  setMenuRightButton,
  setMenuLeftButtonEvent,
  setMenuRightButtonEvent,
  hideMenu,
  showMenu,
  removeWheelMenuButton,
  addWheelMenuButton,
  setGlobalLoading,
} from './app.actions';

export const appKey = 'SmartCo';

export interface AppState {
  wheelMenuButtons: Array<SmartMenuButtonItem>;
  //lettera icona
  menuLeftButton?: { iconText?: string; disabled?: boolean; addClass?: string };
  //lettera icona
  menuRightButton?: { iconText?: string; disabled?: boolean; addClass?: string };
  menuLeftButtonEvent?: MouseEvent;
  menuRightButtonEvent?: MouseEvent;
  menuVisible: boolean;
  loaderCounter: number;
  logs: INGXLoggerMetadata[];
  /** Indica se l'app è pronta per essere usata, se false mostra un loader full-screen */
  loading: boolean;
}

const initialState: AppState = {
  wheelMenuButtons: [],
  loaderCounter: 0,
  logs: [],
  menuLeftButton: undefined,
  menuRightButton: undefined,
  menuLeftButtonEvent: undefined,
  menuRightButtonEvent: undefined,
  menuVisible: false,
  loading: false,
};

const reducer = createReducer(
  initialState,
  on(setWheelMenuButtons, (state, { buttons }) => ({ ...state, wheelMenuButtons: buttons })),
  on(addWheelMenuButton, (state, { button }) => ({ ...state, wheelMenuButtons: [...state.wheelMenuButtons, button] })),
  on(removeWheelMenuButton, (state, { button }) => {
    const index = state.wheelMenuButtons.findIndex((b) => b == button);
    const wheelMenuButtons = [...state.wheelMenuButtons];
    wheelMenuButtons.splice(index, 1);
    return { ...state, wheelMenuButtons };
  }),
  on(setMenuLeftButton, (state, { iconText, disabled, addClass }) => ({ ...state, menuLeftButton: { iconText, disabled, addClass } })),
  on(setMenuRightButton, (state, { iconText, disabled, addClass }) => ({ ...state, menuRightButton: { iconText, disabled, addClass } })),
  on(setMenuLeftButtonEvent, (state, { event }) => ({ ...state, menuLeftButtonEvent: event })),
  on(setMenuRightButtonEvent, (state, { event }) => ({ ...state, menuRightButtonEvent: event })),
  on(hideMenu, (state) => ({ ...state, menuVisible: false })),
  on(showMenu, (state) => ({ ...state, menuVisible: true })),
  on(addLog, (state, { logObject }) => ({ ...state, logs: [...state.logs, logObject] })),
  on(setLogs, (state, { logs }) => ({ ...state, logs: [...logs, ...state.logs] })),
  on(setGlobalLoading, (state: AppState, { loading }) => ({
    ...state,
    loading,
  }))
);

export function rootReducer(state: AppState | undefined, action: Action) {
  return reducer(state, action);
}
