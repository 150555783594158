import { Component, ElementRef, HostBinding, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, Event } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { SmartMenuButtonItem } from '../../models';
import { CurrentPlatformService } from '../../services';
import { getMenuLeftButton, getMenuRightButton, getWheelMenuButtons, setMenuLeftButtonEvent, setMenuRightButtonEvent } from '../../store';
import { filter, Subscription } from 'rxjs';

@Component({
  selector: 'smart-menu-button',
  templateUrl: './smart-menu-button.component.html',
  styleUrls: ['./smart-menu-button.component.scss'],
})
export class SmartMenuButtonComponent implements OnInit, OnDestroy {
  @HostBinding('class.phone') isPhone = this.platform.isPhone;
  private menuStatus = false;
  private classMenuOpen = 'open';
  private classMenuItemSelected = 'selected';
  private classHamburgerActive = 'is-active';

  lastSelectedButtonKey?: string;

  items: Array<SmartMenuButtonItem> = [];
  numberOfReceivedNotifications = 0;

  @ViewChild('smartMenuButtonContainer') smartMenuButtonContainer: ElementRef | undefined;
  @ViewChild('smartMenuButtonHamburger') smartMenuButtonHamburger: ElementRef | undefined;
  leftButton?: { iconText?: string; addClass?: string; disabled?: boolean };
  rightButton?: { iconText?: string; addClass?: string; disabled?: boolean };

  getMenuLeftButton$?: Subscription;
  getMenuRightButton$?: Subscription;
  navigationEnd$?: Subscription;
  getWheelMenuButtons$?: Subscription;
  disabled: boolean | undefined;
  addClass: string | undefined;

  constructor(private router: Router, private navCtrl: NavController, private store: Store, private platform: CurrentPlatformService) {
    this.getMenuLeftButton$ = this.store.select(getMenuLeftButton).subscribe((buttonData) => {
      this.leftButton = buttonData;
    });
    this.getMenuRightButton$ = this.store.select(getMenuRightButton).subscribe((buttonData) => {
      this.rightButton = buttonData;
    });

    this.navigationEnd$ = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((e: unknown) => {
      const event = e as NavigationEnd;
      const selectedItem = this.items.find((item) => event.url.includes(item.pageRoutingKey));
      if (selectedItem) {
        this.lastSelectedButtonKey = selectedItem.pageKey;
      }
      this.menuClose();
    });
  }

  ngOnInit(): void {
    this.getWheelMenuButtons$ = this.store.select(getWheelMenuButtons).subscribe({
      next: (buttons) => {
        this.items = [...buttons].sort((a, b) => a.order - b.order);
        this.lastSelectedButtonKey = this.items.find((item) => this.router.url.includes(item.pageRoutingKey))?.pageKey;
      },
    });
  }

  ngOnDestroy(): void {
    this.getMenuLeftButton$?.unsubscribe();
    this.getMenuRightButton$?.unsubscribe();
    this.navigationEnd$?.unsubscribe();
    this.getWheelMenuButtons$?.unsubscribe();
  }

  newNotificationsReceived() {
    return this.numberOfReceivedNotifications > 0;
  }

  goToPage(pageRouting: string) {
    this.menuClose();
    this.menuStatus = false;
    this.navCtrl.navigateForward(pageRouting);
  }

  menuOpen() {
    this.smartMenuButtonContainer?.nativeElement.classList.add(this.classMenuOpen);
    this.smartMenuButtonHamburger?.nativeElement.classList.add(this.classHamburgerActive);
  }

  menuClose() {
    this.smartMenuButtonContainer?.nativeElement.classList.remove(this.classMenuOpen);
    this.smartMenuButtonHamburger?.nativeElement.classList.remove(this.classHamburgerActive);
  }

  toggleMenu() {
    if (this.menuStatus) {
      this.menuClose();
    } else {
      this.menuOpen();
    }

    this.menuStatus = !this.menuStatus;
  }

  leftButtonClick(event: MouseEvent) {
    if (!this.leftButton?.disabled) {
      this.store.dispatch(setMenuLeftButtonEvent({ event }));
    }
  }

  rightButtonClick(event: MouseEvent) {
    if (!this.rightButton?.disabled) {
      this.store.dispatch(setMenuRightButtonEvent({ event }));
    }
  }
}
