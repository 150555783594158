import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TableModule } from 'ngx-easy-table';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { authServiceInit, ConfiguratorAuthenticationModule, ConfiguratorAuthenticationService } from 'configurator-authentication';
import { createTranslateLoader, ConfiguratorSharedModule } from 'configurator-shared';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appState } from './app.store';
import { ErrorInterceptor } from './interceptor/error.interceptor';
import { JwtInterceptor } from './interceptor/jwt.interceptor';
import { SharedModule } from './utils/shared.module';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ConfiguratorSharedModule.forRoot([{ provide: 'env', useValue: environment }]),
    LoggerModule.forRoot({
      level: environment.logger.level,
      serverLogLevel: NgxLoggerLevel.OFF,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    IonicModule.forRoot(),
    SharedModule,
    ConfiguratorAuthenticationModule.forRoot([{ provide: 'env', useValue: environment }]),
    AppRoutingModule,
    TableModule,
    StoreModule.forRoot(appState),
    EffectsModule.forRoot(),
  ],
  exports: [],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: authServiceInit, deps: [ConfiguratorAuthenticationService], multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
