import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ConfiguratorAuthenticationService } from 'configurator-authentication';
import { ConfiguratorLanguageService, User, UserDataService } from 'configurator-shared';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  showMenu = false;
  userObs = this.userDataService.getAuthenticatedUserObs();

  private isDestroyed = new Subject();

  constructor(
    private router: Router,
    private localizationService: ConfiguratorLanguageService,
    private userDataService: UserDataService,
    private authService: ConfiguratorAuthenticationService
  ) {
    this.localizationService.defaultLanguage = environment.defaultLocale;
    this.localizationService.availableLocales = environment.availableLocales;
    this.localizationService.init();
    this.userDataService.init();
    this.router.events
      .pipe(
        takeUntil(this.isDestroyed),
        filter((e) => e instanceof NavigationStart)
      )
      .subscribe({
        next: (event: NavigationStart) => {
          this.showMenu = true;
          const page = event.url.split('/').pop();
          if (page && ['login', 'password-recovery'].includes(page)) {
            this.showMenu = false;
          }
        },
      });
  }

  ngOnInit(): void {
    this.router.navigate(['home']);
  }

  ngOnDestroy(): void {
    this.isDestroyed.next(true);
    this.isDestroyed.complete();
  }

  async logout() {
    await this.authService.logout();
    this.router.navigate(['home']);
  }

  async login() {
    await this.authService.requestAccess(['home']);
  }
}
