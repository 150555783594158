export enum StructureFieldTypes {
  CONTAINER = 'container',
  ATTACHMENT = 'attachment',
  TEXT = 'text',
  TEXTAREA = 'textarea',
  FILE = 'file',
  BOOLEAN = 'boolean',
  HTML = 'html',
  PHONE = 'phone',
  EMAIL = 'email',
  SELECT = 'select',
  PRIVACY = 'privacy',
  QRCODE = 'qrcode',
  HOTSPOTTER = 'hotspotter',
  IMAGE_MAP_CONTAINER = 'imageMapContainer',
  FEATURE_VALUES_CONTAINER = 'featureValuesContainer',
  RELATED_ENTITIES = 'relatedEntities',
  DATE = 'date',
  CALCULATED = 'calculated',
  NUMBER = 'number',
}
