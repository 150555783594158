import { createAction, props } from '@ngrx/store';
import { Community } from '../../models';

export const setActiveCommunity = createAction('[Community] Set active community', props<{ activeCommunity: Community }>());

// export const setCommunicationsCommunities = createAction(
//   '[Community] Set communications communities',
//   props<{ communityMap: { [uuid: string]: any/*Community*/[] } }>()
// );
// export const addCommunicationsCommunity = createAction(
//   '[Community] Add communication community',
//   props<{ key: string; community: any/*Community*/ }>()
// );
