import { Component, OnInit } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { TableKey } from '../../models';
import { PersistedDataManagerService } from '../../services';
import { UserDataService } from '../../services/user-data.service';

@Component({
  selector: 'smartco-report-button',
  templateUrl: './report-button.component.html',
  styleUrls: ['./report-button.component.scss'],
})
export class ReportButtonComponent implements OnInit {
  constructor(private userData: UserDataService, private localData: PersistedDataManagerService) {}

  ngOnInit(): void {}

  async onReportClick() {
    const user = this.userData.getAuthenticatedUserInstant();
    const szLogs = await this.localData.getRow(TableKey.MAIN, 'logs');
    Browser.open({ url: `mailto:info@addiction.it?subject=report smartCo user ${user?.uuid}&body=${szLogs}`, windowName: '_self' });
  }
}
