import { Directive, ElementRef, HostListener, Inject, Input, OnInit } from '@angular/core';
import { SmartIconComponent } from '../components';
import { EnvironmentConfiguration } from '../interfaces';

@Directive({
  selector: '[togglePassword]',
})
export class TogglePasswordDirective implements OnInit {
  private show = false;
  @Input()
  iconDefault: string = this.env.style.icons.showPassword;
  @Input()
  iconShown: string = this.env.style.icons.hidePassword;

  @HostListener('click')
  onClick() {
    this.toggle();
  }
  @Input('togglePassword')
  inputEl!: HTMLInputElement;

  constructor(private el: SmartIconComponent, @Inject('env') private env: EnvironmentConfiguration) {}

  ngOnInit(): void {
    this.setIcon();
  }

  private toggle() {
    this.show = !this.show;
    // change input type
    this.inputEl.type = this.show ? 'text' : 'password';

    this.setIcon();
  }
  private setIcon() {
    this.el.name = this.show ? this.iconShown : this.iconDefault;
  }
}
