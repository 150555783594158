import { Injectable } from '@angular/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { NGXLogger } from 'ngx-logger';
import { catchError, combineLatest, defer, map, Observable, of, switchMap } from 'rxjs';
import { TableKey } from '../models';
import { PersistedDataManagerService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class LocalMediaService {
  private readonly folder = 'assets';

  constructor(private localDb: PersistedDataManagerService, private logger: NGXLogger) {}

  /** Restituisce il percorso file completo per accedere alla copia locale di un asset della media library */
  /** @param url URL dell'asset della media library */
  /** @param folder nome opzionale della cartella di destinazione */
  getMediaURI(url: string, folder?: string) {
    const path = this.getPathFromURL(url, folder);
    return defer(() => Filesystem.getUri({ path, directory: Directory.Data })).pipe(map((res) => res.uri));
  }

  /** Restituisce il contenuto del file locale di un asset della media library */
  /** @param url URL dell'asset della media library */
  /** @param folder nome opzionale della cartella di destinazione */
  getMediaContent(url: string, folder?: string) {
    const path = this.getPathFromURL(url, folder);
    return defer(() => Filesystem.readFile({ path, directory: Directory.Data })).pipe(map((res) => res.data));
  }
  /** Recupera il MIME type di un asset della media library (offline) */
  /** @param url URL dell'asset della media library */
  getMIMEType(url: string) {
    return this.getAssetMetadata(url).pipe(map((meta) => meta?.mimeType));
  }

  /** Scarica un elemento dalla media library e lo salva localmente nel device, fornendo il progresso di download */
  /** @param url URL dell'asset della media library */
  /** @param folder nome opzionale della cartella di destinazione */
  downloadMedia(url: string, folder?: string, format = ''): Observable<{ progress: number; done: boolean }> {
    if (format?.length) {
      format = '.' + format;
    }
    const savePath = this.getPathFromURL(url + format, folder);

    this.logger.debug('downloading ' + url + ' at ' + savePath);
    return this.download(url).pipe(
      switchMap((downloadStatus) => {
        if (downloadStatus.done)
          return combineLatest([
            this.writeFile(savePath, downloadStatus.data!),
            this.saveAssetMetadata(url, downloadStatus.contentType!, savePath),
          ]).pipe(map(() => ({ progress: 1, done: true })));
        else return of({ progress: downloadStatus.progress, done: false });
      })
    );
  }
  /** Controlla se un asset della media library è già stato scaricato e quindi è disponibile offline  */
  /** @param url URL dell'asset della media library */
  exists(url: string, folder?: string) {
    const path = this.getPathFromURL(url, folder);
    return this.fileExists(path);
  }

  /** Restituisce tutti gli UUID degli asset della media library scaricati */
  getDownloadedUUIDs() {
    return defer(() => this.localDb.getKeys(TableKey.MEDIA));
  }

  /** Recupera l'elenco degli asset scaricati e disponibili offline */
  listMedia() {
    return defer(() => this.localDb.getRows<LocalMediaMetadata>(TableKey.MEDIA));
  }
  /** Rimuove tutti gli elementi scaricati, eliminando i file ed i relativi metadati */
  deleteAll() {
    this.logger.warn('Deleting all locally downloaded media assets!');
    return this.listMedia().pipe(
      switchMap((mediaList) => combineLatest(mediaList.map((media) => this.delete(media)))),
      map(() => true)
    );
  }

  private delete(metadata: LocalMediaMetadata) {
    return defer(async () => {
      await Filesystem.deleteFile({
        directory: Directory.Data,
        path: metadata.savePath,
      });
      await this.localDb.deleteRow(TableKey.MEDIA, metadata.uuid);
    });
  }

  private writeFile(path: string, content: Blob) {
    return defer(() => this.encodeBlob(content)).pipe(
      map((b64) =>
        Filesystem.writeFile({
          path,
          data: b64,
          directory: Directory.Data,
          recursive: true,
        })
      ),
      map(() => true)
    );
  }

  private fileExists(path: string) {
    return defer(() =>
      Filesystem.stat({
        path,
        directory: Directory.Data,
      })
    ).pipe(
      map(() => true),
      catchError((error) => of(false))
    );
  }

  private encodeBlob(blob: Blob): Observable<string> {
    return defer(
      () =>
        new Promise<string>((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.readAsDataURL(blob);
        })
    );
  }

  private saveAssetMetadata(url: string, mimeType: string, savePath: string) {
    const uuid = url.split('/').pop()!;
    const metadata: LocalMediaMetadata = {
      uuid,
      mimeType,
      savePath,
      creationTime: new Date().valueOf(),
    };
    return defer(() => this.localDb.setRow(TableKey.MEDIA, uuid, metadata));
  }

  private getAssetMetadata(url: string) {
    const uuid = url.split('/').pop()!;
    return defer(() => this.localDb.getRow<LocalMediaMetadata>(TableKey.MEDIA, uuid));
  }

  private getPathFromURL(url: string, subFolder?: string) {
    const uuid = url.split('/').pop()!;
    if (subFolder) {
      return `${this.folder}/${subFolder}/${uuid}`;
    }
    return `${this.folder}/${uuid}`;
  }

  private async fetchWithProgress(
    url: string,
    onProgress: (perc: number) => void,
    onComplete: (result: { contentType: string; data: Blob }) => void,
    onError: () => void
  ) {
    let response: Response;
    try {
      response = await fetch(url);
      if (!response || !response.body) throw 'Empty response';
    } catch {
      onError();
      return;
    }

    const reader = response!.body.getReader();

    const contentLength = +response!.headers.get('Content-Length')!;
    const contentType = response!.headers.get('Content-Type')!;
    let received = 0;
    const chunks = new Array<Uint8Array>();
    while (true) {
      try {
        const { done, value } = await reader.read();

        if (done) {
          break;
        }
        chunks.push(value);

        received += value.length;
        onProgress(received / contentLength);
      } catch {
        onError();
        break;
      }
    }

    // download complete - unisce tutti i chunk in un unico buffer
    const array = new Uint8Array(received);
    let pos = 0;
    for (const chunk of chunks) {
      array.set(chunk, pos);
      pos += chunk.length;
    }

    const data = new Blob([array.buffer]);
    onComplete({ contentType, data });
  }
  private download(url: string) {
    // genera un observable da una funzione async con diverse callback
    return new Observable<{ done: boolean; progress: number; contentType?: string; data?: Blob }>((obs) => {
      this.fetchWithProgress(
        url,
        (progress) => obs.next({ progress, done: false }),
        (result) => {
          obs.next({ progress: 1, done: true, ...result });
          obs.complete();
        },
        () => obs.error()
      );
    });
  }
}

interface LocalMediaMetadata {
  uuid: string;
  mimeType: string;
  creationTime: number;
  /** Relative path where this asset is saved */
  savePath: string;
}
