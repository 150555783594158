import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { isObservable, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, skip } from 'rxjs/operators';
import { getMenuLeftButtonEvent, getMenuRightButtonEvent, hideMenu, setMenuLeftButton, setMenuRightButton, showMenu } from '../../store';

@Component({
  template: '',
})
export abstract class BasePageComponent implements OnInit {
  menuLeftEventSub?: Subscription;
  menuRightEventSub?: Subscription;
  menuVisibilitySub?: Subscription;
  menuLeftIcon?: string = undefined;
  menuRightIcon?: string = undefined;

  constructor(protected store: Store) {}

  ionViewDidLeave(): void {
    this.menuRightEventSub?.unsubscribe();
    this.menuLeftEventSub?.unsubscribe();
    this.menuVisibilitySub?.unsubscribe();
  }

  ionViewWillEnter(): void {
    this.store.dispatch(setMenuLeftButton({ iconText: this.menuLeftIcon }));
    this.store.dispatch(setMenuRightButton({ iconText: this.menuRightIcon }));
    this.menuRightEventSub = this.store
      .select(getMenuRightButtonEvent)
      .pipe(skip(1))
      .subscribe((event) => this.onMenuRightClickEvent());
    this.menuLeftEventSub = this.store
      .select(getMenuLeftButtonEvent)
      .pipe(skip(1))
      .subscribe((event) => this.onMenuLeftClickEvent());

    this.handleMenuVisibility();
  }

  abstract onMenuRightClickEvent(): void;

  abstract onMenuLeftClickEvent(): void;

  abstract isMenuVisible(): Observable<boolean> | boolean;

  setMenuLeftButton(iconText: string | undefined) {
    this.menuLeftIcon = iconText;
    this.store.dispatch(setMenuLeftButton({ iconText }));
  }

  setMenuRightButton(iconText: string | undefined) {
    this.menuRightIcon = iconText;
    this.store.dispatch(setMenuRightButton({ iconText }));
  }

  ngOnInit() {}

  private handleMenuVisibility() {
    // toggle menu based on page configuration
    const state = this.isMenuVisible();
    if (isObservable(state)) {
      // async
      this.menuVisibilitySub = state.pipe(distinctUntilChanged()).subscribe((visible) => this.setMenuVisibility(visible));
    } else {
      // sync
      this.setMenuVisibility(state);
    }
  }

  setMenuVisibility(visible: boolean) {
    this.store.dispatch(visible ? showMenu() : hideMenu());
  }
}
