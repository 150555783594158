import { LocalizableField } from './LocalizableField';

export class Language {
  constructor(private _locale: string, private _label: LocalizableField) {}

  get locale() {
    return this._locale;
  }

  get label() {
    return this._label;
  }
}
