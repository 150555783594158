import { Injectable } from '@angular/core';
import { Preferences as Storage } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class PersistedDataManagerService {
  constructor() {}

  private async setData(key: string, value: string): Promise<void> {
    //this.logger.debug('setting ', key, 'with value ', value)
    await Storage.set({ key, value });
  }

  private async getData(key: string): Promise<string | null> {
    return (await Storage.get({ key })).value;
  }

  private async removeByKey(key: string): Promise<void> {
    return await Storage.remove({ key });
  }

  async clearDB(): Promise<void> {
    //this.logger.warn('clear db')
    return await Storage.clear();
  }

  async setRow<T>(table: string, key: string, value: T) {
    const itemKey = `${table}.${key}`;
    return await this.setData(itemKey, JSON.stringify(value));
  }

  async getRow<T>(table: string, key: string): Promise<T | null> {
    const itemKey = `${table}.${key}`;
    const str = await this.getData(itemKey);
    try {
      return str && str !== 'undefined' ? (JSON.parse(str) as T) : null;
    } catch (e) {
      console.error('error parsing json', e);
      return null;
    }
  }

  async getKeys(table: string) {
    const { keys } = await Storage.keys();
    const prefix = table + '.';
    return keys.filter((k) => k.startsWith(prefix)).map((k) => k.substring(prefix.length));
  }

  async getRows<T>(table: string): Promise<T[]> {
    const keys = await this.getKeys(table);
    const rows: T[] = [];
    for (const key of keys) {
      const row = await this.getRow<T>(table, key);
      if (row) rows.push(row);
    }
    return rows;
  }
  async deleteRow(table: string, key: string) {
    const itemKey = `${table}.${key}`;
    await this.removeByKey(itemKey);
  }

  async deleteRows(table: string, keys: string[]) {
    const itemKeys = keys.map((k) => `${table}.${k}`);
    for (const key of itemKeys) this.removeByKey(key);
  }
}
