import { Component, Input } from '@angular/core';

@Component({
  selector: 'smart-icon',
  templateUrl: './smart-icon.component.html',
  styleUrls: ['./smart-icon.component.scss'],
})
export class SmartIconComponent {
  @Input('class') additionalClass: string | undefined;
  @Input('color') color: string | undefined;
  @Input('name') name: string | undefined;

  constructor() {}

  getClassList() {
    let classList = 'icon';
    if (this.additionalClass) {
      classList += ' ' + this.additionalClass;
    }
    return classList;
  }
}
