import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ConfiguratorSharedModule } from './configurator-shared.module';
import { DataleanBaseApiService, PersistedDataManagerService, ConfiguratorLanguageService } from './services';

@Injectable({
  providedIn: ConfiguratorSharedModule,
})
export class ConfiguratorSharedService extends ConfiguratorLanguageService {
  constructor(translateService: TranslateService, persistedStorage: PersistedDataManagerService, private baseApi: DataleanBaseApiService) {
    super(translateService, persistedStorage);
  }

  getConfigurator(configUrl: string, integrationUUID: string): Observable<Integration> {
    return this.baseApi.getEntities<Integration>(`${configUrl}integration/${integrationUUID}`, undefined, []);
  }
}

interface Integration {
  type: 'configurator';
  version: number;
  name: string;
}
