import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { Product, RelatedPositionValue } from 'configurator-shared';
import { ModalRef } from 'src/app/service/modalRef';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader.js';
import {
  Box3,
  BoxHelper,
  BufferGeometry,
  Color,
  DirectionalLight,
  Event,
  Fog,
  GridHelper,
  Material,
  MathUtils,
  Mesh,
  MeshPhongMaterial,
  MeshPhysicalMaterial,
  Object3D,
  PerspectiveCamera,
  PlaneGeometry,
  Scene,
  Vector3,
  WebGLRenderer,
} from 'three/src/Three';

interface RenderModalData {
  currentProduct: Product;
  relatedProduct: Product;
  structureFieldName: string;
  value: RelatedPositionValue;
}

interface FileField {
  attachment_name: string;
  attachment_src: string;
}

@Component({
  selector: 'app-render-trejs-container',
  templateUrl: './render-trejs-container.component.html',
  styleUrls: ['./render-trejs-container.component.scss'],
})
export class RenderTrejsContainerComponent implements OnInit, AfterViewInit {
  @ViewChild('canvas3D') canvas3D: any;
  @ViewChild('buttonTranslate') buttonTranslate: ElementRef;
  @ViewChild('buttonRotate') buttonRotate: ElementRef;
  @ViewChild('loader') loaderOverlay: ElementRef;

  currentProduct: Product;
  relatedProduct: Product;
  currentFile: FileField;
  relatedFile: FileField;

  controlsMode = 'translate';

  private scene: Scene | null;
  private camera: PerspectiveCamera | null;
  private ground: Mesh<PlaneGeometry, MeshPhongMaterial> | null;
  private renderer: WebGLRenderer | null;
  private controls: Object3D<Event> | TransformControls | null;
  private orbit: OrbitControls;
  private loader = new STLLoader();
  // DEFINE MATERIALS
  private materialCastIron = new MeshPhysicalMaterial({
    color: 0x343434,
    metalness: 0.5,
    roughness: 0.7,
    reflectivity: 0.7,
  });

  private materialMetalBrushed = new MeshPhysicalMaterial({
    color: 0x8fc7dc,
    metalness: 0.4,
    reflectivity: 1,
    roughness: 0.3,
  });

  private materialTransparent = new MeshPhysicalMaterial({
    color: 0x8797a1,
    metalness: 0.5,
    roughness: 0.7,
    reflectivity: 0.7,
    transparent: true,
    opacity: 0.15,
    depthTest: false,
    depthWrite: false,
  });
  private baseModelPosition = [0, 0, 0];
  private Box3 = new Box3();
  private Box3Size = new Vector3();
  private cameraY: number;
  private componentModel: Mesh<BufferGeometry, MeshPhysicalMaterial>;
  private pX: number;
  private pZ: number;
  private value: RelatedPositionValue;

  constructor(private modalRef: ModalRef<RelatedPositionValue, RenderModalData>) {}

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 't' || event.key === 'T') {
      this.toggleCommands('translate');
    }
    if (event.key === 'r' || event.key === 'R') {
      this.toggleCommands('rotate');
    }
  }

  ngOnInit(): void {
    const { currentProduct, relatedProduct, structureFieldName, value } = this.modalRef.data;
    this.currentProduct = currentProduct;
    this.relatedProduct = relatedProduct;

    this.currentFile = currentProduct[structureFieldName];
    this.relatedFile = relatedProduct[structureFieldName];

    this.value = value;
  }

  ngAfterViewInit(): void {
    // RESET
    this.scene = null;
    this.camera = null;
    this.ground = null;
    this.renderer = null;
    this.controls = null;
    this.baseModelPosition = [0, 0, 0];

    // CREATE CAMERA
    this.camera = new PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 4000);
    this.camera.position.set(350, 250, 350);

    // CREATE SCENE
    this.scene = new Scene();
    this.scene.background = new Color(0xa0a0a0);
    this.scene.fog = new Fog(0xa0a0a0, 2000, 3000);

    // CREATE LIGHTS
    const directionalLight = new DirectionalLight(0xffffff, 1.2);
    directionalLight.position.set(50, 200, 150);
    directionalLight.shadow.camera.top = 180;
    directionalLight.shadow.camera.bottom = -100;
    directionalLight.shadow.camera.left = -120;
    directionalLight.shadow.camera.right = 120;
    // directionalLight.castShadow = true;
    this.scene.add(directionalLight);

    // CREATE GROUND
    this.ground = new Mesh(new PlaneGeometry(4000, 4000), new MeshPhongMaterial({ color: 0x999999, depthWrite: false }));
    // this.ground.receiveShadow = true;
    this.ground.rotation.x = -Math.PI / 2;
    // this.scene.add( this.ground );

    // CREATE GRID
    const grid = new GridHelper(2000, 20, 0x000000, 0x000000);
    (grid.material as Material).opacity = 0.2;
    (grid.material as Material).transparent = true;
    this.scene.add(grid);

    // RENDERER SETUP
    this.renderer = new WebGLRenderer({ antialias: true, canvas: this.canvas3D.nativeElement, preserveDrawingBuffer: true });
    this.renderer.shadowMap.enabled = true;
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);

    // ADD ORBIT CONTROLS
    this.orbit = new OrbitControls(this.camera, this.renderer.domElement);
    this.orbit.target.set(0, 50, 0);
    this.orbit.update();

    // RENDER SCENE
    this.render();

    // ANIMATE SCENE
    this.animate();

    // LOAD MODELS
    this.loadModel(this.currentFile, 'product');

    // RESIZE MANAGER
    window.addEventListener('resize', this.onWindowResize);
  }

  async loadModel(modelURL: FileField, type: string) {
    const geometry = await this.loader.loadAsync(modelURL.attachment_src);
    const mesh = new Mesh(geometry, type === 'product' ? this.materialTransparent : this.materialMetalBrushed);

    // CREATE BOX HELPER
    const boxHelper = new BoxHelper(mesh);
    this.Box3.setFromObject(boxHelper);
    this.Box3.getSize(this.Box3Size);
    const { min, max } = this.Box3;

    if (type === 'product') {
      const posX = ((max.x - min.x) / 2) * -1;
      const posZ = ((max.z - min.z) / 2) * -1;
      mesh.position.set(posX, 0, posZ);
      this.cameraY = (max.y - min.y) / 2;
      this.baseModelPosition = [posX, 0, posZ];
    } else {
      const { positionX, positionY, positionZ, rotationX = 0, rotationY = 0, rotationZ = 0 } = this.value;
      this.componentModel = mesh;
      const posX = parseFloat(`${positionX}`);
      const posY = parseFloat(`${positionY}`);
      const posZ = parseFloat(`${positionZ}`);
      if (!isNaN(posX) && !isNaN(posY) && !isNaN(posZ)) {
        mesh.position.set(this.baseModelPosition[0] + posX, posY, this.baseModelPosition[2] + posZ);
        mesh.rotation.set(MathUtils.degToRad(+rotationX), MathUtils.degToRad(+rotationY), MathUtils.degToRad(+rotationZ));
      } else {
        mesh.position.set(this.baseModelPosition[0], 0, this.baseModelPosition[2]);
      }
    }

    mesh.castShadow = true;
    mesh.receiveShadow = true;
    this.scene?.add(mesh);

    if (type === 'product') {
      this.loadModel(this.relatedFile, 'component');
    } else {
      // CREATE OBJECT CONTROL
      if (this.camera) {
        this.controls = new TransformControls(this.camera, this.renderer?.domElement);
        if (this.controls instanceof TransformControls) {
          this.controls.setSize(1.2);
          this.controls.setRotationSnap(MathUtils.degToRad(45));
        }
        this.controls.addEventListener('change', () => this.render());
        this.controls.attach(mesh);
        this.controls.addEventListener('mouseDown', () => (this.orbit.enabled = false));
        this.controls.addEventListener('mouseUp', () => (this.orbit.enabled = true));
      }
      if (this.controls) {
        this.scene?.add(this.controls);
      }
      this.loaderOverlay.nativeElement.classList.add('loaded');
    }
  }

  animate() {
    window.requestAnimationFrame((_) => this.animate());
    this.render();
  }

  render() {
    this.camera?.lookAt(0, this.cameraY, 0);
    if (this.scene && this.camera) {
      this.renderer?.render(this.scene, this.camera);
    }
  }

  onWindowResize() {
    if (this.camera) {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
    }
    this.renderer?.setSize(window.innerWidth, window.innerHeight);
    this.render();
  }

  onCancel() {
    this.modalRef.close();
  }

  onSave() {
    this.pX = this.componentModel.position.x - this.baseModelPosition[0];
    this.pZ = this.componentModel.position.z - this.baseModelPosition[2];

    this.modalRef.close({
      positionX: this.pX,
      positionY: this.componentModel.position.y,
      positionZ: this.pZ,
      rotationX: MathUtils.radToDeg(this.componentModel.rotation.x),
      rotationY: MathUtils.radToDeg(this.componentModel.rotation.y),
      rotationZ: MathUtils.radToDeg(this.componentModel.rotation.z),
    });
  }

  toggleCommands(mode: 'translate' | 'rotate' | 'scale') {
    if (this.controls instanceof TransformControls) {
      this.controls.mode = mode;
    }
    this.controlsMode = mode;
  }
}
