import { HttpClient, HttpEvent } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PaginationInfo, SearchInfo, SortInfo, UrlBuilder } from '../models';
import { EnvironmentConfiguration, Parts, UnknownObject } from '../interfaces';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataleanBaseApiService {
  constructor(@Inject('env') private environment: EnvironmentConfiguration, private http: HttpClient) {}

  getEntities<T>(
    endpoint: string,
    additionalParams: UnknownObject | undefined,
    parts: Parts[],
    searchInfo?: SearchInfo,
    paginationInfo?: PaginationInfo,
    sortInfo?: SortInfo
  ): Observable<T> {
    const requestUrlBuilder = new UrlBuilder(endpoint).withOrganizationUUID(this.environment.organizationUUID).withParts(parts);

    if (additionalParams) {
      const key = Object.keys(additionalParams).find((k) => k.includes('ommunityUUID'));
      if (key) {
        const { communityEntityUUID, ...params } = additionalParams;
        additionalParams = params;
      }
      for (const filter of Object.keys(additionalParams)) {
        if (additionalParams[filter] !== undefined) {
          // console.log('adding filter for %s = %s', filter, additionalParams[filter]);
          requestUrlBuilder.withQueryParam(filter, additionalParams[filter] as string);
        }
      }
    }

    if (paginationInfo) {
      requestUrlBuilder.withPaginationInfo(paginationInfo);
    }

    if (sortInfo && sortInfo.sortBy !== undefined) {
      requestUrlBuilder.withSortInfo(sortInfo);
    }

    if (searchInfo?.query && searchInfo?.searchFields) {
      requestUrlBuilder.withSearchFilter(searchInfo);
    }

    return this.http.get<T>(requestUrlBuilder.build());
  }

  getEntity<T>(endpoint: string, entityUUID: string, parts: Parts[], params?: UnknownObject): Observable<T> {
    const lastCharSlash = endpoint.slice(-1) === '/';
    if (!lastCharSlash) endpoint += '/';

    let builder = new UrlBuilder(endpoint + entityUUID).withOrganizationUUID(this.environment.organizationUUID).withParts(parts);

    if (params) builder = builder.withAdditionalParameter(params);

    const requestUrl = builder.build();
    return this.http.get<T>(requestUrl);
  }

  createEntity<T = unknown>(
    endpoint: string,
    newEntity: unknown,
    parts: Parts[],
    additionalParameter = {},
    entityIsFile = false,
    options?: any
  ): Observable<HttpEvent<T>> {
    const requestUrl = new UrlBuilder(endpoint)
      .withOrganizationUUID(this.environment.organizationUUID)
      .withParts(parts)
      .withAdditionalParameter(additionalParameter)
      .build();
    return this.http.post<T>(requestUrl, newEntity, options);
  }

  updateEntity<T = unknown>(endpoint: string, updatedEntity: unknown, parts: Parts[]): Observable<T> {
    if (updatedEntity?.['uuid']) {
      const lastCharSlash = endpoint.slice(-1) === '/';
      if (!lastCharSlash) endpoint += '/';
      const requestUrl = new UrlBuilder(endpoint + updatedEntity?.['uuid'])
        .withOrganizationUUID(this.environment.organizationUUID)
        .withParts(parts)
        .build();
      return this.http.put<T>(requestUrl, updatedEntity);
    }
    return throwError(() => new Error('update failed'));
  }

  deleteEntity<T = unknown>(endpoint: string, entityToDelete: UnknownObject, key?: string): Observable<T> {
    if ((key && entityToDelete[key]) || entityToDelete?.['uuid']) {
      const lastCharSlash = endpoint.slice(-1) === '/';
      if (!lastCharSlash) endpoint += '/';
      const keValue = key ? entityToDelete[key] : entityToDelete?.['uuid'];
      const requestUrl = new UrlBuilder(endpoint + keValue)
        .withOrganizationUUID(this.environment.organizationUUID)
        .withAdditionalParameter(entityToDelete)
        .build();
      return this.http.delete<T>(requestUrl);
    } else {
      return throwError(() => new Error('update failed'));
    }
  }
}
