import { environment } from 'src/environments/environment';
import { OAuth2AuthenticateOptions } from '@byteowls/capacitor-oauth2';

export const authCapConfig: OAuth2AuthenticateOptions = {
  authorizationBaseUrl: environment.authenticationUrl + 'auth',
  accessTokenEndpoint: environment.authenticationUrl + 'token',
  // resourceUrl: environment.authenticationUrl + 'token',
  scope: 'openid profile email', // Ask offline_access to support refresh token refreshes
  logsEnabled: !environment.production,
  pkceEnabled: true,
  additionalParameters: {
    organizationUUID: environment.organizationUUID,
    userType: 'ApplicationUser@configuratorUser',
    prompt: 'login',
  },
  web: {
    appId: 'dataleanFeIntegration',
    responseType: 'code',
    redirectUrl: window.location.origin,
    windowOptions: 'height=600,left=0,top=0',
    windowTarget: 'top',
  },
  android: {
    appId: 'omfb-integration',
    responseType: 'code', // if you configured a android app in google dev console the value must be "code"
    redirectUrl: 'com.omfb.configurator.app:/', // package name from google dev console
  },
  ios: {
    appId: 'omfb-integration',
    responseType: 'code', // if you configured a ios app in google dev console the value must be "code"
    redirectUrl: 'com.omfb.configurator.app:/', // Bundle ID from google dev console
  },
};
