import { AnimationController, createAnimation } from '@ionic/angular';

export function topToBottom(baseEl: HTMLElement) {
  const AnimationC = new AnimationController();
  const backdropAnimation = AnimationC.create();
  backdropAnimation.addElement(baseEl.shadowRoot!.querySelector('ion-backdrop')!);
  const wrapperAnimation = AnimationC.create();
  wrapperAnimation.addElement(baseEl.shadowRoot!.querySelector('.modal-wrapper')!).keyframes([
    { offset: 0, opacity: '0', transform: 'translateY(-100%)' },
    { offset: 1, opacity: '0.99', transform: 'translateX(0%)' },
  ]);
  backdropAnimation.fromTo('opacity', 0.01, 0.7);
  return AnimationC.create()
    .addElement(baseEl)
    .easing('ease-in-out')
    .duration(300)
    .beforeAddClass('show-modal')
    .addAnimation([backdropAnimation, wrapperAnimation]);
}

const getIonPageElement = (element: HTMLElement) => {
  if (element.classList.contains('ion-page')) {
    return element;
  }

  const ionPage = element.querySelector(':scope > .ion-page, :scope > ion-nav, :scope > ion-tabs');
  if (ionPage) {
    return ionPage;
  }
  // idk, return the original element so at least something animates
  // and we don't have a null pointer
  return element;
};

export function pageTransition(_: HTMLElement, opts: any) {
  const DURATION = 300;

  // root animation with common setup for the whole transition
  const rootTransition = createAnimation()
    .duration(opts.duration || DURATION)
    .easing('cubic-bezier(0.3,0,0.66,1)');

  // ensure that the entering page is visible from the start of the transition
  const enteringPage = createAnimation().addElement(getIonPageElement(opts.enteringEl)).beforeRemoveClass('ion-page-invisible');

  // create animation for the leaving page
  const leavingPage = createAnimation().addElement(getIonPageElement(opts.leavingEl));

  // actual customized animation
  if (opts.direction === 'forward') {
    enteringPage.fromTo('transform', 'translateX(100%)', 'translateX(0)');
    leavingPage.fromTo('opacity', '1', '0.25');
  } else {
    leavingPage.fromTo('transform', 'translateX(0)', 'translateX(100%)');
    enteringPage.fromTo('opacity', '0.25', '1');
  }

  // include animations for both pages into the root animation
  rootTransition.addAnimation(enteringPage);
  rootTransition.addAnimation(leavingPage);
  return rootTransition;
}
