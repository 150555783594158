import { Pipe, PipeTransform } from '@angular/core';
import { LocalizableField } from '../models/LocalizableField';
import { ConfiguratorLanguageService } from '../services';

@Pipe({
  name: 'toLocalizedValuePipe',
})
export class ToLocalizedValuePipe implements PipeTransform {
  constructor(private localizationService: ConfiguratorLanguageService) {}

  transform(value: any, forcedLocale?: string) {
    if (value === undefined || value === null) {
      return '';
    }
    const defaultLan = this.localizationService.defaultLanguage;
    const activeLan = forcedLocale || this.localizationService.activeLanguage;
    if (value instanceof LocalizableField) {
      return value.getLocalizedValue(activeLan, defaultLan);
    } else if (
      typeof value === 'string' ||
      value instanceof String ||
      typeof value === 'number' ||
      value instanceof Number ||
      typeof value === 'boolean'
    ) {
      return value;
    } else if (Array.isArray(value)) {
      return value.find((v) => v.language === (activeLan || defaultLan))?.value || '';
    } else {
      return value?.value ?? value?.[activeLan] ?? value?.[defaultLan] ?? '';
    }
  }
}
