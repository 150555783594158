import { LocalizableField } from './LocalizableField';

export class CommunicationStructure {
  static readonly UUID_FIELD_KEY = 'uuid';
  static readonly NAME_FIELD_KEY = 'name';
  static readonly FIELDS_KEY = 'structureFields';
  static readonly PRIORITY_STRUCTURE_FIELD_KEY = 'priority';
  static readonly MARKER_COLOR_STRUCTURE_FIELD_KEY = 'markerColor';
  static readonly STRUCTURE_FIELD_PREDEFINED_VALUE_KEY = 'predefinedValue';

  constructor(private _uuid: string, private _name: LocalizableField, private _priority: number, private _markerColor: string) {}

  get uuid() {
    return this._uuid;
  }

  get name() {
    return this._name;
  }

  get priority() {
    return this._priority;
  }

  get markerColor() {
    return this._markerColor;
  }
}
