<div #smartMenuButtonContainer class="smart-menu-button-container" [ngClass]="'item-number-'+items.length">
  <div class="smart-menu-button-content">
    <div class="smart-menu-button-left">
      <smart-icon *ngIf="leftButton?.iconText" [attr.disabled]="leftButton?.disabled ? '': null"  class="smart-menu-item-icon left-menu-icon" [ngClass]="rightButton?.addClass ? rightButton!.addClass! :''" [name]="leftButton?.iconText"
        (click)="leftButtonClick($event)"></smart-icon>
    </div>
    <div *ngIf="newNotificationsReceived()" class="smart-menu-notification smart-menu-notification-main">
      {{numberOfReceivedNotifications}}
    </div>
    <ng-container *ngIf="isPhone;else desktop_block">
      <div class="navigation-wheel">
        <div *ngFor="let item of items" #smartMenuItem [ngClass]="' smart-menu-item ' + item.pageRoutingKey" [class.selected]="lastSelectedButtonKey === item.pageKey"        > 
          <div *ngIf="item.notificationNumber" class="smart-menu-notification">{{item.notificationNumber}}</div>
          <smart-icon class="smart-menu-item-icon" name="{{item.icon}}" (click)="goToPage(item.pageRoutingKey)"></smart-icon>
        </div>
      </div>
      <div class="hamburger hamburger--collapse" #smartMenuButtonHamburger (click)="toggleMenu()">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </div>
    </ng-container>
    <ng-template #desktop_block>
      <div *ngFor="let item of items" #smartMenuItem [ngClass]="' smart-menu-item ' + item.pageRoutingKey"  [class.selected]="lastSelectedButtonKey === item.pageKey">
      <div *ngIf="item.notificationNumber" class="smart-menu-notification">{{item.notificationNumber}}</div>
      <smart-icon class="smart-menu-item-icon" name="{{item.icon}}" (click)="goToPage(item.pageRoutingKey)"></smart-icon>
    </div>
    </ng-template>
    <div class="smart-menu-button-right">
      <smart-icon *ngIf="rightButton?.iconText" [attr.disabled]="rightButton?.disabled ? '': null" [ngClass]="rightButton?.addClass ? rightButton!.addClass! :''" class="smart-menu-item-icon right-menu-icon" [name]="rightButton?.iconText"
        (click)="rightButtonClick($event)"></smart-icon>
    </div>
  </div>
</div>