import { FeatureValue, Feedback } from '../../interfaces';
import { Communication } from '../../interfaces';

export const COMMUNICATIONS_TAG = 'communications';

//TODO typing
export interface CommunicationsState {
  communications: { [featureValueUUID: string]: Communication[] };
  featureValues: FeatureValue[];
  activeCommunication: Communication | undefined;
  activeCommunicationTab: number;
  feedbackMap: { [uuid: string]: Feedback[] };
  isLoading: boolean;
}
