import { DatePipe } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';
import { EnvironmentConfiguration } from '../interfaces';
import { ConfiguratorLanguageService } from '../services';

@Pipe({
  name: 'toDate',
})
export class ToDatePipe extends DatePipe implements PipeTransform {
  constructor(
    private localizationService: ConfiguratorLanguageService,
    @Inject('env') private environmentSettings: EnvironmentConfiguration
  ) {
    super(localizationService.activeLanguage);
  }

  transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  transform(value: Date | string | number | null | undefined, format?: string, timezone?: string, locale?: string): string | null {
    //const dateOptions: any = {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'};
    if (!format) {
      return super.transform(
        value,
        this.environmentSettings.style.dateFormat ?? 'dd/MM/yyyy',
        undefined,
        this.localizationService.activeLanguage
      );
    }
    return super.transform(value, format, timezone, locale);
  }
}
