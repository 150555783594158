export class ContestScore {
  public static readonly UUID_FIELD_KEY: string = 'uuid';
  public static readonly CONTEST_UUID_FIELD_KEY: string = 'contestUUID';
  public static readonly TOTAL_SCORE_FIELD_KEY: string = 'totalScore';

  constructor(private _uuid: string, private _contestUUID: string, private _totalScore: number, private _contest: Contest) {}

  get userUUID(): string {
    return this._uuid;
  }

  get contestUUID(): string {
    return this._contestUUID;
  }

  get totalScore(): number {
    return this._totalScore;
  }

  get contest(): Contest {
    return this._contest;
  }
}

export class Contest {
  public static readonly START_DATE_FIELD_KEY: string = 'startDate';
  public static readonly END_DATE_FIELD_KEY: string = 'endDate';
  public static readonly LIMIT_SCORE_FIELD_KEY: string = 'limitScore';

  constructor(private _startDate: Date, private _endDate: Date, private _limitScore: number) {}

  get limitScore(): number {
    return this._limitScore;
  }
  get startDate(): Date {
    return this._startDate;
  }
  get endDate(): Date {
    return this._endDate;
  }
}
