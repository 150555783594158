import { UserStructureField } from '../../interfaces';

export class UserValueBuilder {
  private _id: string | undefined;
  private _type: string | undefined;
  private _structureFieldName: string | undefined;

  constructor(private _structureFieldUUID: string, private _value: string) {}

  get structureFieldUUID() {
    return this._structureFieldUUID;
  }

  get value() {
    return this._value;
  }

  get id() {
    return this._id;
  }

  get type() {
    return this._type;
  }

  get structureFieldName() {
    return this._structureFieldName;
  }

  withId(id: string): UserValueBuilder {
    this._id = id;
    return this;
  }

  withType(type: string): UserValueBuilder {
    this._type = type;
    return this;
  }

  withStructureFieldName(structureFieldName: string): UserValueBuilder {
    this._structureFieldName = structureFieldName;
    return this;
  }

  build() {
    return new UserValue(this);
  }
}

export class UserValue {
  static readonly UUID_FIELD_KEY = 'uuid';
  static readonly STRUCTURE_FIELD_UUID_FIELD_KEY = 'structureFieldUUID';
  static readonly STRUCTURE_FIELD_NAME_FIELD_KEY = 'structureFieldName';
  static readonly TYPE_FIELD_KEY = 'type';
  static readonly VALUE_FIELD_KEY = 'value';

  private readonly _id: string | undefined;
  private readonly _structureFieldUUID: string;
  private readonly _structureFieldName: string | undefined;
  private _type: string | undefined;
  private _value: string | undefined;

  //TODO remove when migrate to new be
  public userStructureField: UserStructureField | undefined;

  constructor(userValueBuilder: UserValueBuilder) {
    this._id = userValueBuilder.id;
    this._structureFieldUUID = userValueBuilder.structureFieldUUID;
    this._type = userValueBuilder.type;
    this._value = userValueBuilder.value;
    this._structureFieldName = userValueBuilder.structureFieldName;
  }

  get id() {
    return this._id;
  }

  get structureFieldUUID() {
    return this._structureFieldUUID;
  }

  get structureFieldName() {
    return this._structureFieldName;
  }

  get type() {
    return this._type;
  }

  get value() {
    return this._value;
  }

  set value(value: string | undefined) {
    this._value = value;
  }

  set type(type: string | undefined) {
    this._type = type;
  }
}
