import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AuthState, UnknownObject, User } from 'configurator-shared';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ConfiguratorAuthenticationService } from '../configurator-authentication.service';

@Component({
    selector: 'smart-password-recovery',
    templateUrl: './password-recovery.component.html',
    styleUrls: ['./password-recovery.component.scss'],
})
export class PasswordRecoveryComponent {
    submitted = false;
    recoveryForm: FormGroup;
    disableButton = true;
    @ViewChild('recoveryPasswordFormDiv') recoveryPasswordFormDiv!: ElementRef;
    @Output() goBack = new EventEmitter<void>();

    constructor(private formBuilder: FormBuilder, private authService: ConfiguratorAuthenticationService, private store: Store<AuthState>) {
        this.recoveryForm = this.formBuilder.group({
            password_recovery: ['', Validators.compose([Validators.required])],
        });
    }

    passwordRecovery(value: UnknownObject) {
        if (!this.recoveryForm) {
            return;
        }
        if (this.recoveryForm.valid) {
            this.disableButton = true;
            const username = value['password_recovery'] as string;
            this.authService
                .getUserForPasswordRecovery(username)
                .pipe(
                    catchError(() => {
                        this.disableButton = false;
                        this.submitted = true;
                        return of(undefined);
                    }),
                    switchMap((data: User | undefined) => {
                        if (data) {
                            this.hideRecoveryPasswordErrorMessage();
                            return this.authService.postUserUUIDForPasswordRecovery(data.uuid);
                        }
                        return of(undefined);
                    }),
                )
                .subscribe({
                    complete: () => {
                        this.disableButton = false;
                        this.submitted = true;
                    },
                });
        }
    }

    onPasswordRecoveryInput() {
        this.disableButton = !this.recoveryForm.get('password_recovery')?.value;
    }

    hideRecoveryPasswordErrorMessage() {
        document.querySelector('#recovery-response-success')?.classList.add('hidden');
        document.querySelector('#recovery-response-error')?.classList.add('hidden');
    }
}
