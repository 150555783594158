import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Capacitor } from '@capacitor/core';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { LocalMediaService } from '../services';

@Pipe({
  name: 'toLocalUrl',
})
export class LocalUrlPipe implements PipeTransform {
  constructor(private localMedia: LocalMediaService, private sanitizer: DomSanitizer) {}

  /**
   *
   * @param value url
   * @param folder
   * @param format
   * @param sanitize use bypassSecurityTrustUrl
   * @param convert use convertFileSrc
   * @returns url locale se disponibile, altrimenti url remoto
   */
  transform(value: string, folder?: string, format?: string, sanitize = true, convert = true): Observable<SafeUrl | string | undefined> {
    if (Capacitor.isNativePlatform() && value?.length) {
      let formatWithDot = '';
      if (format?.length) {
        formatWithDot = '.' + format;
      }
      return this.localMedia.exists(value + formatWithDot, folder).pipe(
        switchMap((exists) => {
          if (exists) {
            return this.localMedia.getMediaURI(value + formatWithDot, folder).pipe(
              map((uri) => {
                let urlToReturn: string | SafeUrl = uri;
                if (convert) {
                  urlToReturn = Capacitor.convertFileSrc(uri);
                }
                if (sanitize) {
                  urlToReturn = this.sanitizer.bypassSecurityTrustUrl(urlToReturn as string);
                }
                return urlToReturn;
              })
            );
          } else {
            return this.localMedia.downloadMedia(value, folder, format).pipe(
              filter((d) => d.done),
              map(() => value)
            );
          }
        }),
        startWith(value)
      );
    }
    return of(value);
  }
}
