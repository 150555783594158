import { AbstractControl, ValidatorFn } from '@angular/forms';
import { validatePassword } from '../utils';

/** Valida la complessità della password */
export function passwordComplexity(): ValidatorFn {
  return (control: AbstractControl) => {
    const validationResult = validatePassword(control.value as string);

    if (validationResult.isValid()) return null;

    const errors = validationResult.getErrors();
    // mappa il risultato ad un oggetto adatto alle reactive forms
    const error: { [key: string]: any } = {
      complexity: errors,
    };
    for (const failedCheck of errors) {
      error[failedCheck] = true;
    }
    return error;
  };
}
