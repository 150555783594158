// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: false,
  hasRegistration: true,
  name: 'OMFB CONFIGURATOR',
  organizationPrefix: 'OMFB',
  organizationUUID: 'e944f698-7598-4417-b675-a57ef8481977',
  activeLanguage: 'it-IT',
  tableStructureUUID: '141ffb4a-620c-493c-a5a2-3ae7783ba3a0',
  checkDevice: false,
  userStructureUUID: '91d8ff29-ccaa-4400-9e8b-1f8806ada83e',
  configUrl: 'https://configuration.datalean-nodejs-dev.catalean.com/',
  authenticationUrl: 'https://auth.datalean-nodejs-dev.catalean.com/',
  configuratorUrl: 'https://configurator.datalean-nodejs-dev.catalean.com/configurator/',
  projectUrl: 'https://configurator.datalean-nodejs-dev.catalean.com/project/',
  notificationTokenUrl: 'https://push-notification.datalean-nodejs-dev.catalean.com/notification/register/',
  organizationUrl: 'https://organization.datalean-nodejs-dev.catalean.com/organization/',
  usersUrl: 'https://user.datalean-nodejs-dev.catalean.com/user/application/',
  generalFeedbackUrl: 'https://dataleandev.catalean.com/generalfeedback/',
  userStructureUrl: 'https://structure.datalean-nodejs-dev.catalean.com/applicationUserStructure/',
  // communicationsUrl: 'https://core-entities.datalean-nodejs-dev.catalean.com/communication/',
  productUrl: 'https://core-entities.datalean-nodejs-dev.catalean.com/product/',
  featuresUrl: 'https://feature.datalean-nodejs-dev.catalean.com/feature/',
  structuresUrl: 'https://structure.datalean-nodejs-dev.catalean.com/structure/',
  surveyUrl: 'https://survey.datalean-nodejs-dev.catalean.com/survey/',
  surveyResponseUrl: 'https://survey.datalean-nodejs-dev.catalean.com/response/',
  contestUrl: 'https://contest.datalean-nodejs-dev.catalean.com/contest/',
  communityUrl: 'https://community.datalean-nodejs-dev.catalean.com/community/',

  // authenticationUrl: 'http://localhost:3020/',
  // configuratorUrl: 'http://localhost:3015/configurator/',
  // projectUrl: 'http://localhost:3015/project/',
  // usersUrl: 'http://localhost:3002/user/application/',
  // productUrl: 'http://localhost:3006/product/',

  availableLocales: ['it-IT', 'en-US'],
  defaultLocale: 'it-IT',
  appId: 'com.omfb.configurator.app',
  logger: {
    level: NgxLoggerLevel.DEBUG,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
