import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { LocalizableField, Product, RelatedPositionValue, StructureFieldTypes } from 'configurator-shared';
import { v4 } from 'uuid';
import { BaseQuestion, Section } from './sections';

export enum ProductQuestionType {
  QUESTION = 'QUESTION', // Domanda singola, la risposta è la risposta alle domande successive
  QUESTIONS = 'QUESTIONS', // Serie di domande, la risposta è l'array di risposta alle domande
  ANSWER = 'ANSWER', // Risposta singola, in array si usa OR
  ANSWERS = 'ANSWERS', // Risposte multiple, in array si usa AND
}

export enum ProductQuestionInterface {
  QUESTION = 'Single Question', // Domanda singola, la risposta è la risposta alle domande successive
  QUESTIONS = 'Multiple Question', // Serie di domande, la risposta è l'array di risposta alle domande
  ANSWER = 'Single Answer', // Risposta singola, in array si usa OR
  ANSWERS = 'Multiple Answer', // Risposte multiple, in array si usa AND
}

export class Configuration implements BaseQuestion {
  uuid = v4();
  label = new LocalizableField();
  value: FormGroupValue = {};
  type: ProductQuestionType;
  answers: Array<Configuration> = [];
  order = 0;
  organizationUUID = '';
  isRelated = false;
  isSectionController = false;
  relatedSection?: string = undefined;
  fieldType: StructureFieldTypes = StructureFieldTypes.TEXT;
  validatorList: Record<string, string> = {};

  constructor(public parentUUID?: string) {
    this.type = ProductQuestionType.ANSWER;
  }
}

export type FlatSection = Array<Section | Configuration>;
export type BaseObjectFormValue = Record<string, string | number | Partial<{ [x: string]: string | number }>>;
export type BaseFormGroupValue = string | number;
export type FormGroupValue = BaseFormGroupValue | Record<string, BaseFormGroupValue | BaseObjectFormValue> | Array<BaseObjectFormValue>;

export type ModifiedProduct = Omit<Product, 'relatedPosition'> & { relatedPosition: Array<Product | RelatedPositionValue | null> };
export type ConfigurationFormControl =
  | FormControl<string | number | LocalizableField>
  | FormGroup<{
      [key: string]: AbstractControl<any, any>;
    }>
  | FormArray<FormGroup>;
export type GenericFormGroup<T> = FormGroup<GenericForm<T>>;
export type GenericFormArray<T> = FormArray<GenericFormGroup<T>>;
export type FromArraySection = GenericFormGroup<Partial<Configuration & { id: string }>>;
export type ConfigurationForm = FormGroup<{
  name: FormControl<string>;
  customer: FormControl<string>;
  referent: FormControl<string>;
  omfbreferent: FormControl<string>;
  sections: FormArray<FromArraySection>;
}>;
export type GenericForm<Form> = {
  [key in keyof Form]: AbstractControl<Form[key]>;
};

export type ConfigurationFormValue = {
  [key in keyof ConfigurationForm['value']]: ConfigurationForm['value'][key];
} & { uuid: string };
