import { createFeatureSelector, createSelector } from '@ngrx/store';
import { appKey, AppState } from './app.reducers';

export const selectApp = createFeatureSelector<AppState>(appKey);

export const getLogs = createSelector(selectApp, (state: AppState) => state?.logs);
export const getWheelMenuButtons = createSelector(selectApp, (state: AppState) => state?.wheelMenuButtons);
export const getMenuLeftButton = createSelector(selectApp, (state: AppState) => state?.menuLeftButton);
export const getMenuRightButton = createSelector(selectApp, (state: AppState) => state?.menuRightButton);
export const getMenuLeftButtonEvent = createSelector(selectApp, (state: AppState) => state?.menuLeftButtonEvent);
export const getMenuRightButtonEvent = createSelector(selectApp, (state: AppState) => state?.menuRightButtonEvent);
export const getIsMenuVisible = createSelector(selectApp, (state) => state.menuVisible);
export const getGlobalLoading = createSelector(selectApp, (state) => state.loading);
