import { HttpClient } from '@angular/common/http';
import { Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { catchError, map } from 'rxjs/operators';
import { firstValueFrom, Observable, of } from 'rxjs';
import { EnvironmentConfiguration, Feedback, Parts } from '../interfaces';
import { ConfigurationInfo, EntityType, FeedbackType, KEY, OrganizationConfiguration, TableKey, UrlBuilder } from '../models';
import { addCommunicationFeedback } from '../store';
import { DataleanBaseApiService } from './datalean-base-api.service';
import { PersistedDataManagerService } from './persisted-data-manager.service';
import { UserDataService } from './user-data.service';

//MAKE MODULE
@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  private canSendFeedback = false;

  tmpFeedbacks: dbFeedback[] = [];
  constructor(
    @Inject('env') private environment: EnvironmentConfiguration,
    private http: HttpClient,
    private baseApi: DataleanBaseApiService,
    private dbService: PersistedDataManagerService,
    private store: Store,
    private logger: NGXLogger,
    private userData: UserDataService
  ) {}

  init() {
    return this.baseApi.getEntity(this.environment.configUrl + 'config', '', [Parts.EMPTY]).pipe(
      map((data) => {
        //this.mapConfiguration(data as ConfigurationInfo[]);
        this.canSendFeedback = true;
        if (this.canSendFeedback) {
          this.processOfflineFeedbacks();
        } else {
          this.logger.warn('feedback disabilitati per mancanza configurazione');
        }
      })
    );
  }

  private async getFeedbackOfflineQueue(): Promise<dbFeedback[]> {
    try {
      return (await this.dbService.getRow<dbFeedback[]>(TableKey.MAIN, KEY.feedbackDb)) || [];
    } catch {
      this.logger.warn('feedback malformattati, rimozione dei feedback passati');
      this.dbService.deleteRow(TableKey.MAIN, KEY.feedbackDb);
      return [];
    }
  }
  private async clearFeedbackOfflineQueue() {
    await this.dbService.deleteRow(TableKey.MAIN, KEY.feedbackDb);
  }

  private async processOfflineFeedbacks() {
    if (this.tmpFeedbacks.length > 0) {
      this.tmpFeedbacks.forEach(async (dbFeedback: dbFeedback) => {
        await firstValueFrom(this.processFeedback(dbFeedback.feedback, dbFeedback.endpoint));
      });
    }
    (await this.getFeedbackOfflineQueue()).forEach(async (dbFeedback: dbFeedback) => {
      //TODO valutare di rimuovere firstValueFrom
      await firstValueFrom(this.processFeedback(dbFeedback.feedback, dbFeedback.endpoint));
    });
    // clear the queue
    this.clearFeedbackOfflineQueue();
  }

  sendFeedback(feedback: Feedback, endpoint?: string): Observable<Feedback | undefined> {
    if (this.canSendFeedback || feedback.entityType == EntityType.COMMUNICATION) {
      const userData = this.userData.getAuthenticatedUserInstant();
      if (!feedback.userUUID) {
        feedback.userUUID = userData?.uuid;
      }
      return this.processFeedback(feedback, endpoint || (this.environment.usersUrl as string));
    } else {
      this.tmpFeedbacks.push({ feedback, endpoint: endpoint || (this.environment.usersUrl as string) });
      return of(undefined);
    }
  }

  private processFeedback(feedback: Feedback, endpoint: string) {
    const requestUrl = new UrlBuilder(endpoint + feedback.userUUID + '/feedback/')
      .withOrganizationUUID(this.environment.organizationUUID)
      .build();
    return this.http.post(requestUrl, feedback).pipe(
      map((data) => {
        if (feedback.entityType === EntityType.COMMUNICATION) {
          this.store.dispatch(addCommunicationFeedback({ key: feedback.entityUUID, feedback }));
          this.addCommunicationFeedbackToDB(feedback).catch((err) => console.error(err));
        }

        return data as Feedback;
      }),
      catchError((error) => {
        this.store.dispatch(addCommunicationFeedback({ key: feedback.entityUUID, feedback }));
        this.addFeedbackToOfflineQueue(endpoint, feedback);
        return of(undefined);
      })
    );
  }

  private async addFeedbackToOfflineQueue(endpoint: string, feedback: Feedback) {
    this.getFeedbackOfflineQueue().then((feedBacks: dbFeedback[]) => {
      feedBacks.push({ endpoint, feedback });
      this.dbService.setRow(TableKey.MAIN, KEY.feedbackDb, feedBacks);
    });
  }

  private async addCommunicationFeedbackToDB(feedback: Feedback) {
    const dbFeedbacks = await this.dbService.getRow<{ [uuid: string]: Feedback[] }>(TableKey.MAIN, KEY.COMMUNICATIONS_FEEDBACK);
    if (dbFeedbacks) {
      if (dbFeedbacks[feedback.entityUUID]) {
        const foundDbfeedback = dbFeedbacks[feedback.entityUUID].find(
          (dbFeedback: Feedback) => dbFeedback.feedbackType == feedback.feedbackType
        );
        if (foundDbfeedback) {
          foundDbfeedback.counter ? foundDbfeedback.counter++ : (foundDbfeedback.counter = 1);
        } else {
          dbFeedbacks[feedback.entityUUID].push(feedback);
        }
      } else {
        dbFeedbacks[feedback.entityUUID] = [feedback];
      }
      await this.dbService.setRow(TableKey.MAIN, KEY.COMMUNICATIONS_FEEDBACK, dbFeedbacks);
    }
  }

  private mapConfiguration(responseData: ConfigurationInfo[]): OrganizationConfiguration {
    const configurationMap: { [key: string]: ConfigurationInfo } = {};

    const defaultLocaleKey = 'defaultLocale';
    const activeLocalesKey = 'activeLocales';

    responseData.forEach((remoteConfiguration: ConfigurationInfo) => {
      configurationMap[remoteConfiguration.key] = remoteConfiguration;
      switch (remoteConfiguration.key) {
        case defaultLocaleKey:
          configurationMap[remoteConfiguration.key].value = remoteConfiguration.value;
          break;
        case activeLocalesKey:
          configurationMap[remoteConfiguration.key].value = remoteConfiguration.value;
          break;
        default:
          break;
      }
    });

    return new OrganizationConfiguration(configurationMap);
  }

  getEntityFeedbacks(endpoint: string, entityUUID: string, userUUID: string, feedbackTypes: FeedbackType[]) {
    return this.baseApi.getEntities(endpoint + userUUID + '/feedback/', { entityUUID, feedbackType: feedbackTypes.join(',') }, [
      Parts.EMPTY,
    ]);
  }

  getEntitiesFeedbacks(endpoint: string, userUUID: string, feedbackTypes: FeedbackType[]) {
    return this.baseApi.getEntities(endpoint + userUUID + '/feedback/', { feedbackType: feedbackTypes.join(',') }, [Parts.EMPTY]);
  }
}

interface dbFeedback {
  endpoint: string;
  feedback: Feedback;
}
