<div *ngIf="showDate" class="date-container">
  <input id="calendar" [placeholder]="'SMARTCO.DATE' | translate" [value]="date | date : 'dd/MM/YYYY'" />
  <ion-modal #modalDate class="datepicker-calendar" trigger="calendar">
    <ng-template>
      <ion-datetime
        #ionDatetime
        max="2099"
        [locale]="languageService.activeLanguage"
        [presentation]="'date'"
        [disabled]="disabled"
        [showDefaultButtons]="true"
        [value]="date"
        (ionChange)="dateChange($event)">
        <ion-buttons slot="buttons">
          <ion-button class="return" color="primary" fill="clear" (click)="modalDate.dismiss()"></ion-button>
          <ion-button class="ok" color="primary" fill="clear" (click)="ionDatetime.confirm(); modalDate.dismiss()"></ion-button>
        </ion-buttons>
      </ion-datetime>
    </ng-template>
  </ion-modal>
</div>
<div *ngIf="showTime" class="time-container">
  <input [ngxTimepicker]="timePicker" [format]="24" [value]="time" [disabled]="disabled" [placeholder]="'SMARTCO.TIME' | translate" />
  <ngx-material-timepicker #timePicker (timeSet)="timeChange($event)" [minutesGap]="5"></ngx-material-timepicker>
</div>
