import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';

/** Validatore che controlla se due formcontrol sono uguali */
export function mustMatch(firstControlName: string, secondControlName: string, errorName = 'mustMatch'): ValidatorFn {
  return (control: AbstractControl) => {
    if (control instanceof FormGroup) {
      // here we have the 'passwords' group
      const pass = control.get(firstControlName)?.value;
      const confirmPass = control.get(secondControlName)?.value;
      return pass === confirmPass ? null : { [errorName]: true };
    }
    return null;
  };
}
