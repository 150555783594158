import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import SwiperCore, { Zoom } from 'swiper';
SwiperCore.use([Zoom]);

@Component({
  selector: 'app-full-screen-image',
  templateUrl: './full-screen-image.component.html',
  styleUrls: ['./full-screen-image.component.scss'],
})
export class FullScreenImageComponent implements OnInit {
  @Input() image?: string;
  @Input() format?: string;

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  closeModal() {
    this.modalController.dismiss();
  }
}
