import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LocalMediaService, logout } from 'configurator-shared';
import { NGXLogger } from 'ngx-logger';
import { EMPTY, catchError, of, switchMap, tap } from 'rxjs';
import { ConfiguratorAuthenticationService } from '../configurator-authentication.service';
import { setAuthError } from './auth.actions';

@Injectable()
export class AuthEffects {
    logout$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(logout),
                switchMap(() =>
                    this.localMedia.deleteAll().pipe(
                        catchError((e) => {
                            this.logger.error(JSON.stringify(e));
                            return of(undefined);
                        }),
                    ),
                ),
                tap(() => {
                    this.authService.logout();
                }),
            ),
        { dispatch: false },
    );

    // handle redirection when there is a problem with authentication
    error$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setAuthError),
            switchMap((url) => {
                // effettua il redirect al login tranne nelle aree pubbliche
                if (!this.isGuestArea(this.router.url)) {
                    return of(logout());
                    //this.authService.navigateToLoginPage();
                }
                return EMPTY;
            }),
        ),
    );

    constructor(
        private actions$: Actions,
        private router: Router,
        private authService: ConfiguratorAuthenticationService,
        private localMedia: LocalMediaService,
        private logger: NGXLogger,
    ) {}

    private isGuestArea(url: string) {
        // TODO: da rendere più deterministico, per esempio usando i data-attribute sulle route
        return url.startsWith('/login');
    }
}
