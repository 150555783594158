import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { skipWhile } from 'rxjs';
import { ConfiguratorAuthenticationService } from '../configurator-authentication.service';

@Component({
    selector: 'login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent {
    @Output() registrationClick = new EventEmitter<boolean>();
    @Input() hasRegistration = false;
    @Input() externalSubmission?: boolean;

    @Output() hasSubmitted = new EventEmitter<boolean>();
    @Input() set hasFinishedSubmission(value: boolean | null) {
        if (value) this.onSubmit();
    }

    constructor(private authenticationService: ConfiguratorAuthenticationService, private router: Router) {}

    onSubmit() {
        this.authenticationService.isDoneLoading$.pipe(skipWhile((x) => !x)).subscribe(() => {
            this.authenticationService.requestAccess(['']);
        });
    }

    manageSubmission() {
        if (this.externalSubmission) {
            this.hasSubmitted.emit(true);
        } else this.onSubmit();
    }
}
