import { ActionReducerMap } from '@ngrx/store';
import { AppState, rootReducer } from 'configurator-shared';

export interface IAppState {
  SmartCo: AppState;
}

export const appState: ActionReducerMap<IAppState> = {
  SmartCo: rootReducer,
};
