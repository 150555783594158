import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  SmartIconComponent,
  SmartMenuButtonComponent,
  FullScreenImageComponent,
  ReportButtonComponent,
  LogModalComponent,
} from './components';
import { ToLocalizedValuePipe, LocalUrlPipe, ToDatePipe, BytesToSizePipe } from './pipes';
import { rootReducer } from './store';
import { SwiperModule } from 'swiper/angular';
import SwiperCore, { Pagination } from 'swiper';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { DatetimePickerComponent } from './components/datetime-picker/datetime-picker.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { TogglePasswordDirective } from './directives';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './store/app/app.effects';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
// install Swiper modules
SwiperCore.use([Pagination]);

@NgModule({
  declarations: [
    FullScreenImageComponent,
    SmartIconComponent,
    SmartMenuButtonComponent,
    ToLocalizedValuePipe,
    LogModalComponent,
    ReportButtonComponent,
    ToDatePipe,
    LocalUrlPipe,
    BytesToSizePipe,
    LoadingIndicatorComponent,
    DatetimePickerComponent,
    TogglePasswordDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SwiperModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      extend: true,
    }),
    StoreModule.forFeature('sharedAppState', rootReducer),
    EffectsModule.forFeature([AppEffects]),
    RoundProgressModule,
    NgxMaterialTimepickerModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SmartIconComponent,
    SmartMenuButtonComponent,
    FullScreenImageComponent,
    ReportButtonComponent,
    ToLocalizedValuePipe,
    LocalUrlPipe,
    ToDatePipe,
    BytesToSizePipe,
    TranslateModule,
    SwiperModule,
    LoadingIndicatorComponent,
    DatetimePickerComponent,
    TogglePasswordDirective,
  ],
  providers: [],
})
export class ConfiguratorSharedModule {
  static forRoot(providers: Provider[]): ModuleWithProviders<ConfiguratorSharedModule> {
    return {
      ngModule: ConfiguratorSharedModule,
      providers,
    };
  }
}
