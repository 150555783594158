import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Configuration, GenericFormGroup, ProductQuestionType } from '../models/configurations';
import { Project } from '../models/project';
import { StructureFieldTypes } from 'configurator-shared';

@Component({
  selector: 'app-fieldset',
  templateUrl: './fieldset.component.html',
  styleUrls: ['./fieldset.component.scss'],
})
export class FieldsetComponent implements OnInit, OnDestroy {
  @Input() question: Configuration;
  @Input() form: GenericFormGroup<Partial<Configuration>>;
  @Input() defaultValue?: Partial<Project>;

  readonly StructureFieldTypes = StructureFieldTypes;
  readonly QuestionType = ProductQuestionType;

  constructor() {}

  ngOnDestroy(): void {
    this.form.get(this.question.uuid)?.disable();
  }

  ngOnInit() {
    // console.log(this.form.controls[this.question.uuid]);
    this.form.get(this.question.uuid)?.enable();
    if (this.defaultValue) {
      const answer = this.defaultValue[this.question.uuid];
      this.newSelection({ value: answer } as MatSelectChange);
      this.form.patchValue({ [answer]: this.defaultValue[answer] });
    }
  }

  getMinMax(question: Configuration, choose: string) {
    const validator = Object.entries(question.validatorList).find(([key, value]) => key === choose);
    if (!!validator) {
      return validator[1];
    }
    return choose === 'min' ? 0 : Infinity;
  }

  changeNumber(value: number, question: Configuration) {
    const newValue = this.form.get(question.uuid)?.value + value;
    if (newValue >= this.getMinMax(question, 'min') && newValue <= this.getMinMax(question, 'max')) {
      this.form.patchValue({ [question.uuid]: newValue });
      // console.log('AAAAAAAAAAAA');
      this.form.updateValueAndValidity();
    }
  }

  newSelection(event: MatSelectChange) {
    const activeAnswers = this.question.answers.filter((a) => this.form.contains(a.uuid));
    for (const a of activeAnswers) {
      if (this.form.contains(a.uuid) && this.form.get(a.uuid)?.enabled) {
        this.form.get(a.uuid)?.disable();
        this.form.removeControl(a.uuid);
      }
    }
    const answer = this.question.answers.find((a) => a.uuid === event.value);
    if (answer) {
      switch (answer.type) {
        case ProductQuestionType.QUESTION: {
          const formGroup = new FormGroup({});
          formGroup.addControl(answer.uuid, new FormControl('', Validators.required));
          this.form.addControl(event.value, formGroup);
          this.form.get(event.value)?.enable();
          this.form.updateValueAndValidity();
          break;
        }
        case ProductQuestionType.QUESTIONS: {
          const formArray = new FormArray<FormGroup>([]);
          for (const a of answer.answers) {
            const formGroup = new FormGroup({});
            formGroup.addControl(a.uuid, new FormControl('', Validators.required));
            formArray.push(formGroup);
          }

          this.form.addControl(event.value, formArray);
          this.form.get(event.value)?.enable();
          this.form.updateValueAndValidity();
          break;
        }
      }
    }
  }
}
