import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TableKey } from '../models';
import { PersistedDataManagerService } from './persisted-data-manager.service';

@Injectable({
  providedIn: 'root',
})
export class ConfiguratorLanguageService {
  _activeLanguage = new BehaviorSubject<string>('');

  constructor(private translateService: TranslateService, private persistedStorage: PersistedDataManagerService) {}

  async init() {
    const localStorageLanguage = await this.persistedStorage.getRow<string>(TableKey.MAIN, 'language');
    this.activeLanguage = localStorageLanguage || this.defaultLanguage;
  }

  get activeLanguage(): string {
    return this._activeLanguage.getValue();
  }

  set activeLanguage(language: string) {
    const chosenLanguage = language || this.translateService.defaultLang;
    this.translateService.use(chosenLanguage).subscribe(() =>
      this.persistedStorage.setRow(TableKey.MAIN, 'language', chosenLanguage).then(() => {
        this._activeLanguage.next(chosenLanguage);
      })
    );
  }

  get defaultLanguage(): string {
    return this.translateService.getDefaultLang();
  }

  set defaultLanguage(language: string) {
    this.translateService.setDefaultLang(language);
  }

  get availableLocales(): string[] {
    return this.translateService.getLangs();
  }

  set availableLocales(languages: string[]) {
    this.translateService.addLangs(languages);
  }

  translate(stringToTranslate: string): string {
    return this.translateService.instant(stringToTranslate);
  }

  observeActiveLanguage(): Observable<string> {
    return this._activeLanguage.asObservable();
  }
}
