import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { map, skipWhile } from 'rxjs/operators';
import { ConfiguratorAuthenticationService } from '../configurator-authentication.service';

@Injectable({
    providedIn: 'root',
})
export class LoginGuard {
    constructor(private navCtrl: NavController, private authService: ConfiguratorAuthenticationService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.authService.isDoneLoading$.pipe(
            skipWhile((x) => x != true),
            map((x) => {
                if (!this.authService.hasValidToken()) {
                    this.navCtrl.navigateRoot(['']);
                    return false;
                } else {
                    return true;
                }
            }),
        );
    }
}
