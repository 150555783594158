import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'smartco-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.css'],
})
export class LoadingIndicatorComponent implements OnInit {
  @Input()
  progress = 0;

  constructor() {}

  ngOnInit(): void {}
}
