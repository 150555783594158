import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { INGXLoggerMetadata } from 'ngx-logger';
import { getLogs } from '../../store/app/app.selectors';

@Component({
  selector: 'smartco-log-modal',
  templateUrl: './log-modal.component.html',
  styleUrls: ['./log-modal.component.scss'],
})
export class LogModalComponent implements OnInit {
  logs: INGXLoggerMetadata[] = [];

  constructor(private store: Store, private modalCtrl: ModalController) {
    this.store.select(getLogs).subscribe((logs) => {
      this.logs = logs;
    });
  }

  ngOnInit(): void {}

  close() {
    this.modalCtrl.dismiss();
  }
}
