import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfiguratorAuthenticationService } from 'configurator-authentication';

@Injectable({
  providedIn: 'root',
})
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: ConfiguratorAuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    if (!request.url.includes(environment.authenticationUrl)) {
      if (this.authService.idToken /* && !request.url.includes('registration')*/) {
        request = request.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.authService.idToken, //ELIMINARE SE LOGIN NON PRESENTE
          },
        });
      }
    }
    return next.handle(request);
  }
}
