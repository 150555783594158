export class CommunityBuilder {
  private _imageSrc: string | undefined;
  private _id: string | undefined;
  private _uuid: string | undefined;
  private _category: string | undefined;
  private _team: string | undefined;
  private _code: string | undefined;
  private _shortUrl: string | undefined;
  private _active: boolean | undefined;
  private _schemaVersion: string | undefined;

  constructor(private _name: string) {}

  get name() {
    return this._name;
  }

  get UUID() {
    return this._uuid;
  }

  get isActive() {
    return this._active;
  }

  get shortUrl() {
    return this._shortUrl;
  }

  get schemaVersion() {
    return this._schemaVersion;
  }

  get code() {
    return this._code;
  }

  get id() {
    return this._id;
  }

  get imageSrc() {
    return this._imageSrc;
  }

  get team() {
    return this._team;
  }

  get category() {
    return this._category;
  }

  withImageSrc(imageSrc: string): CommunityBuilder {
    this._imageSrc = imageSrc;
    return this;
  }

  withId(id: string): CommunityBuilder {
    this._id = id;
    return this;
  }

  withSchemaVersion(schemaVersion: string): CommunityBuilder {
    this._schemaVersion = schemaVersion;
    return this;
  }

  setActive(active: boolean): CommunityBuilder {
    this._active = active;
    return this;
  }

  withCode(code: string): CommunityBuilder {
    this._code = code;
    return this;
  }

  withTeam(team: string): CommunityBuilder {
    this._team = team;
    return this;
  }

  withCategory(category: string): CommunityBuilder {
    this._category = category;
    return this;
  }

  withShortUrl(url: string): CommunityBuilder {
    this._shortUrl = url;
    return this;
  }

  withUUID(UUID: string): CommunityBuilder {
    this._uuid = UUID;
    return this;
  }

  build() {
    return new Community(this);
  }
}

export class Community {
  static readonly COMMUNITY_NAME = 'name';
  static readonly IMAGESRC = 'logo';
  static readonly UUID_FIELD_KEY = 'uuid';

  private readonly _name: string;
  private readonly _category: string | undefined;
  private readonly _imageSrc: string | undefined;
  private readonly _id: string | undefined;
  private readonly _team: string | undefined;
  private readonly _code: string | undefined;
  private readonly _shortUrl: string | undefined;
  private readonly _schemaVersion: string | undefined;
  private readonly _active: boolean | undefined;
  //TODO remove on new be
  private readonly _uuid: string | undefined;

  constructor(communityBuilder: CommunityBuilder) {
    this._name = communityBuilder.name;
    this._imageSrc = communityBuilder.imageSrc;
    this._category = communityBuilder.category;
    this._id = communityBuilder.id;
    this._team = communityBuilder.team;
    this._code = communityBuilder.code;
    this._shortUrl = communityBuilder.shortUrl;
    this._active = communityBuilder.isActive;
    this._uuid = communityBuilder.UUID;
    this._schemaVersion = communityBuilder.schemaVersion;
  }

  get name() {
    return this._name;
  }

  get imageSrc() {
    return this._imageSrc;
  }

  get schemaVersion() {
    return this._schemaVersion;
  }

  get category() {
    return this._category;
  }

  get isActive() {
    return this._active;
  }

  get team() {
    return this._team;
  }

  get UUID() {
    return this._uuid;
  }

  get shortUrl() {
    return this._shortUrl;
  }

  get code() {
    return this._code;
  }

  get id() {
    return this._id;
  }
}
