<div class="full-screen-image-container">
  <ion-buttons>
    <ion-button (click)="closeModal()">
      <span class="icon-close"></span>
    </ion-button>
  </ion-buttons>
  <swiper [zoom]="true">
    <ng-template swiperSlide class="zoom-section" [zoom]="true" *ngIf="image">
      <img [src]="image | toLocalUrl : 'gallery' : format | async" />
    </ng-template>
  </swiper>
</div>
