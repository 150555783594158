import { createAction, props } from '@ngrx/store';
import { User } from '../../interfaces';
import { AuthenticationToken } from '../../models';

//TODO typing
export const setUserData = createAction('[Auth] Set User Data', props<{ userData?: User }>());
export const setAuthToken = createAction('[Auth] Set Token', props<{ authenticationToken?: AuthenticationToken }>());
export const passwordChanged = createAction('[Auth] User Password Changed');

export const prelogout = createAction('[Auth] Pre-Logout');
export const logout = createAction('[Auth] Logout');
