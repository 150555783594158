import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { from, map, of, skipWhile, switchMap } from 'rxjs';
import { ConfiguratorAuthenticationService } from 'configurator-authentication';
import { UserDataService } from 'configurator-shared';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(private authService: ConfiguratorAuthenticationService, private userDataService: UserDataService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.isDoneLoading$.pipe(
      skipWhile((x) => x !== true),
      switchMap((x) => {
        if (!!this.userDataService.getAuthenticatedUserInstant() && !!this.authService.idToken) {
          return of(true);
        }
        // console.log(
        //   'LoginGuard: canActivate: redirecting to login',
        //   route.url.map((v) => v.path)
        // );
        return from(this.authService.requestAccess(route.url.map((v) => v.path)));
      })
    );
  }
}
