import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, catchError } from 'rxjs';
import { ResponseStatus } from 'configurator-shared';
import { NGXLogger } from 'ngx-logger';
import { ConfiguratorAuthenticationService } from 'configurator-authentication';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: ConfiguratorAuthenticationService, private router: Router, private logger: NGXLogger) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === ResponseStatus.UNAUTHORIZED) {
          // auto logout if 401 response returned from api
          // this.store.dispatch(logout());
          this.authService.logout();
          this.logger.error('unauthorized');
          this.router.navigate(['/login']);

          const error = err.error.message || err.statusText;
          return throwError(() => new Error('unauthorized'));
        }
        return throwError(() => new Error('error ' + err.status));
      })
    );
  }
}
