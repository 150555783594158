import { createAction, props } from '@ngrx/store';
import { INGXLoggerMetadata } from 'ngx-logger';
import { SmartMenuButtonItem } from '../../models';

export const setWheelMenuButtons = createAction('[SmartCo] Set Menu Buttons', props<{ buttons: Array<SmartMenuButtonItem> }>());
export const addWheelMenuButton = createAction('[SmartCo] Insert Menu Button', props<{ button: SmartMenuButtonItem }>());
export const removeWheelMenuButton = createAction('[SmartCo] Remove Menu Button', props<{ button: SmartMenuButtonItem }>());

export const setMenuLeftButton = createAction(
  '[SmartCo] Set Menu Left Button',
  props<{ iconText?: string; addClass?: string; disabled?: boolean }>()
);
export const setMenuRightButton = createAction(
  '[SmartCo] Set Menu Right Button',
  props<{ iconText?: string; addClass?: string; disabled?: boolean }>()
);
export const setMenuLeftButtonEvent = createAction('[SmartCo] Set Menu Left Button Event', props<{ event: MouseEvent }>());
export const setMenuRightButtonEvent = createAction('[SmartCo] Set Menu Right Button Event', props<{ event: MouseEvent }>());

export const hideMenu = createAction('[SmartCo] Hide Menu');
export const showMenu = createAction('[SmartCo] Show Menu');

export const setGlobalLoading = createAction('[SmartCo] Set Global Loading', props<{ loading: boolean }>());
export const addLog = createAction('[SmartCo] Add Log', props<{ logObject: INGXLoggerMetadata }>());
export const setLogs = createAction('[SmartCo] Set Log', props<{ logs: INGXLoggerMetadata[] }>());
