import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { INGXLoggerMonitor, INGXLoggerMetadata } from 'ngx-logger';
import { first, take } from 'rxjs';
import { TableKey } from '../models';
import { addLog, getLogs } from '../store';
import { PersistedDataManagerService } from './persisted-data-manager.service';

@Injectable({
  providedIn: 'root',
})
export class LoggerMonitor implements INGXLoggerMonitor {
  private localDataService: PersistedDataManagerService;
  private readonly SAVED_DAYS_TIMESTAMP = 24 * 60 * 60 * 2;
  private readonly DB_KEY = 'logs';

  private oldLogs?: INGXLoggerMetadata[];
  constructor(private store: Store) {
    this.localDataService = new PersistedDataManagerService();
    this.fetchDBData();
  }

  onLog(logObject: INGXLoggerMetadata): void {
    this.store.dispatch(addLog({ logObject }));
    if (this.oldLogs) {
      this.store
        .select(getLogs)
        .pipe(first())
        .subscribe((logs: INGXLoggerMetadata[]) => {
          if (logs.length) {
            this.setDBLogs([...this.oldLogs!, ...logs]);
          }
        });
    }
  }

  setDBLogs(logs: INGXLoggerMetadata[]) {
    this.localDataService.setRow(TableKey.MAIN, this.DB_KEY, logs);
  }

  async fetchDBData() {
    const szLogs = await this.localDataService.getRow<Array<INGXLoggerMetadata>>(TableKey.MAIN, this.DB_KEY);

    if (szLogs) {
      let logs = szLogs;
      //TODO rimuovi vecchi
      const currentTimestamp = new Date().getTime();
      logs = logs.filter((log: INGXLoggerMetadata) => {
        return currentTimestamp - new Date(log.timestamp ?? 0).getTime() < this.SAVED_DAYS_TIMESTAMP;
      });
      this.oldLogs = logs;
    } else {
      this.oldLogs = [];
    }

    this.store
      .select(getLogs)
      .pipe(take(1))
      .subscribe((logs: INGXLoggerMetadata[]) => {
        if (logs.length) {
          this.setDBLogs([...this.oldLogs!, ...logs]);
        }
      });
  }
}
