import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bytesToSize',
})
export class BytesToSizePipe implements PipeTransform {
  constructor() {}

  transform(value?: string | number): any {
    if (!value) {
      return '-';
    }
    if (typeof value === 'string') {
      value = parseInt(value, 10);
    }
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (value == 0) return '0 Byte';
    const i: number = Math.floor(Math.log(value) / Math.log(1024));
    return Math.floor(value / Math.pow(1024, i)) + ' ' + sizes[i];
  }
}
