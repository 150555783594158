import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class CurrentPlatformService extends Platform {
  get isPhone() {
    return this.is('iphone') || (this.is('android') && !this.is('tablet'));
  }
}
