export class OrganizationConfiguration {
  constructor(private _configurationList: { [key: string]: ConfigurationInfo }) {}

  getConfiguration(key: string): ConfigurationInfo {
    return this._configurationList[key];
  }

  get defaultLocale() {
    return this._configurationList['defaultLocale'];
  }

  get activeLocales() {
    return this._configurationList['activeLocales'];
  }
}

export interface ConfigurationInfo {
  sensitive: boolean;
  type: string;
  value: string | string[];
  key: string;
  uuid: string;
}
