import { Action, createReducer, on } from '@ngrx/store';
import { logout, setUserData, AuthState, setAuthToken, passwordChanged } from 'configurator-shared';
import { setAuthError } from './auth.actions';

const initialState: AuthState = {
    authenticationToken: undefined,
    userData: undefined,
    authError: undefined,
};

const _authReducer = createReducer(
    initialState,
    on(setUserData, (state: AuthState, { userData }) => ({
        ...state,
        userData,
    })),
    on(setAuthError, (state: AuthState, { authError }) => ({
        ...state,
        authError,
    })),
    on(setAuthToken, (state: AuthState, { authenticationToken }) => ({
        ...state,
        authenticationToken,
    })),
    on(passwordChanged, (state: AuthState) => ({
        ...state,
        userData: {
            ...state.userData,
            status: 'ENABLED',
        },
    })),
    //TODO effect clear db navigate login
    on(logout, () => ({ ...initialState })),
);

export const AuthReducer = (state: AuthState | undefined, action: Action) => {
    return _authReducer(state, action);
};
