export enum FeedbackType {
  HAS_OPENED = 'has_opened',
  HAS_CONFIRMED = 'has_confirmed',
  HAS_ANSWERED = 'has_answered',
  HAS_USED = 'has_used',
  LIKES = 'likes',
  IS_FAVOURITE = 'is_favourite',
  HAS_LOGGED = 'has_logged',
  HAS_ACCESSED = 'has_accessed',
  HAS_SEEN = 'has_seen',
}

export enum KEY {
  sendFeedbackConfigurationKey = 'sendFeedbackSmartCo',
  feedbackDb = 'feedbacksToSend',
  COMMUNICATIONS_FEEDBACK = 'communications_feedbacks',
  COCKPIT = 'cockpit',
  /** Indica se l'utente ha già effettuato un accesso all'app. Se è TRUE significa che l'ha già fatto */
  hasDoneFirstAccess = 'hasDoneFirstAccess',
}
