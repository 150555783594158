export class SmartMenuButtonItem {
  static readonly DEFAULT_MAIN = new SmartMenuButtonItem('main', 'main', 'c', 5, 0);
  static readonly DEFAULT_WELCOMEKIT_LIST = new SmartMenuButtonItem('welcome-kit-list', 'welcome-kit-list', 'w', 10, 0);
  static readonly DEFAULT_TUTORIALS = new SmartMenuButtonItem('tutorials', 'tutorials', 'u', 15, 0);
  static readonly DEFAULT_COCKPIT = new SmartMenuButtonItem('cockpit', 'cockpit', 'p', 25, 0);
  static readonly DEFAULT_COMMUNITY = new SmartMenuButtonItem('community-selection', 'community-selection', 'b', 35, 0);
  static readonly DEFAULT_PROFILE = new SmartMenuButtonItem('profile', 'profile', 'o', 40, 0);

  constructor(
    private _pageRoutingKey: string,
    private _pageKey: string,
    private _icon: string,
    private _order: number,
    private _notificationNumber?: number
  ) {}

  get pageRoutingKey() {
    return this._pageRoutingKey;
  }

  get order() {
    return this._order;
  }

  get pageKey() {
    return this._pageKey;
  }

  get icon() {
    return this._icon;
  }

  get notificationNumber() {
    return this._notificationNumber;
  }
}
