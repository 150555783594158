export enum EntityType {
  STRUCTURE = 'Structure',
  STRUCTURE_FIELD = 'StructureField',
  FEATURE_VALUE = 'FeatureValue',
  FEATURE = 'Feature',
  PRODUCT = 'Product',
  AUTHMETHOD = 'AuthenticationMethod',
  COMMUNICATION = 'Communication',
  INTEGRATION = 'Integration',
}
