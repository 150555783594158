import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommunitiesState, COMMUNITY_TAG } from './community.state';

const CommunitiesSelector = createFeatureSelector<CommunitiesState>(COMMUNITY_TAG);

export const getActiveCommunity = createSelector(CommunitiesSelector, (state: CommunitiesState) => state?.activeCommunity);
export const getCommunities = createSelector(CommunitiesSelector, (state: CommunitiesState) => state?.communities);

// export const getCommunityMap = createSelector(CommunitiesSelector, (state: CommunitiesState) => state?.communityMap);
// export const getCommunicationsCommunities = (communicationUUID: string) =>
//   createSelector(CommunitiesSelector, (state: CommunitiesState) => state?.communityMap[communicationUUID]);
