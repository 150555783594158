import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { map, skipWhile, switchMap } from 'rxjs/operators';
import { ConfiguratorAuthenticationService } from '../configurator-authentication.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    constructor(private navCtrl: NavController, private auth: ConfiguratorAuthenticationService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.auth.isDoneLoading$.pipe(
            skipWhile((done) => !done),
            switchMap(() =>
                combineLatest([this.auth.isAuthenticated$, this.auth.userNeedPrivacyValidation(), this.auth.userMustChangePassword()]),
            ),
            map(([authenticated, needPrivacy, mustChangePw]) => {
                // controlla gli step necessari per poter usare l'app, in ordine di importanza
                if (!authenticated) {
                    this.navCtrl.navigateRoot(['/login']);
                    console.log('User not authorized');
                    return false;
                } else if (needPrivacy) {
                    this.navCtrl.navigateRoot(['/login', 'privacy']);
                    console.log('User must agree with privacy');
                    return false;
                } else if (mustChangePw) {
                    this.navCtrl.navigateRoot(['/login', 'change-password']);
                    console.log('User must change their password');
                    return false;
                }
                console.log('User is ok, can use app');
                return true;
            }),
        );
    }
}
