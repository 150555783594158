import { HttpErrorResponse } from '@angular/common/http';
import { AuthenticationToken } from '../../models';

export const AUTH_TAG = 'auth';

export interface AuthState {
  authenticationToken?: AuthenticationToken;
  //TODO typing
  userData?: any;
  authError?: HttpErrorResponse;
}
