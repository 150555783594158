export class DateRange {
  constructor(private _from: string, private _to: string) {}

  get from() {
    return this._from;
  }

  set from(from: string) {
    this._from = from;
  }

  get to() {
    return this._to;
  }

  set to(to: string) {
    this._to = to;
  }
}
