export class AuthenticationToken {
  constructor(private _token: string | null, private _expirationTimestamp?: string) {
    if (!this._token) {
      throw new Error('Token cannot be empty');
    }

    if (!this._expirationTimestamp) {
      this._expirationTimestamp = `${+this.payload['exp'] * 1000}`;
    }
  }

  get token() {
    return this._token;
  }

  get expirationTimeStamp() {
    return this._expirationTimestamp;
  }

  get isExpired() {
    if (this.expirationTimeStamp) {
      return +this.expirationTimeStamp < Date.now();
    }
    return false;
  }

  get payload() {
    return this.decodePayload(this.getTokenPayload());
  }

  private getTokenPayload() {
    if (this.token) {
      const tokenComponents = this.token.split('.');
      if (tokenComponents.length == 3) {
        return tokenComponents[1];
      }
    }
    return undefined;
  }

  private decodePayload(payload: string | undefined): { [key: string]: string } {
    if (!payload) {
      return {};
    }
    try {
      return JSON.parse(atob(payload));
    } catch (e) {
      return {};
    }
  }

  getPayloadParameter(parameterKey: string) {
    const payload = this.getTokenPayload();
    return this.decodePayload(payload)?.[parameterKey];
  }

  isValid() {
    return !!this.token && !this.isExpired;
  }

  isExpiring() {
    if (this.expirationTimeStamp) {
      const expirationDate = new Date(this.expirationTimeStamp);
      const now = new Date();
      const diff = expirationDate.getTime() - now.getTime();
      return diff < 1000 * 60 * 5;
    }
    return false;
  }
}
