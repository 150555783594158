import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConfiguratorLanguageService } from '../../services';

@Component({
  selector: 'smartco-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DatetimePickerComponent,
      multi: true,
    },
  ],
})
export class DatetimePickerComponent implements OnInit, ControlValueAccessor {
  //@Input() model: Date;
  @Input() showDate = true;
  @Input() showTime = true;
  @Input() value!: string;
  @Input() disabled = false;
  @Output() valueChange = new EventEmitter<string>();

  time = '';
  date = '';

  private onChange = (value: string) => {};
  private onTouched = () => {};

  constructor(public languageService: ConfiguratorLanguageService) {}

  writeValue(obj: string | undefined): void {
    if (obj && typeof obj != 'string') throw 'SmartcoDateTimePicker wants a string value';
    if (obj) this.setFromISOString(obj);
    else this.clearValue();
  }
  registerOnChange(fn: (val: string) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  ngOnInit() {
    if (this.value) {
      this.setFromISOString(this.value);
    }
  }

  timeChange(event: string) {
    this.time = event;
    this.prepareAndEmitNewValue();
  }

  dateChange(date: any) {
    this.date = date.detail.value ?? '';
    this.prepareAndEmitNewValue();
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
  private setFromISOString(isoString: string) {
    this.value = isoString;
    if (isoString) {
      const date = new Date(this.value);
      if (this.showDate) {
        this.date = date.toISOString();
      }
      if (this.showTime) {
        this.time = date.toLocaleTimeString(this.languageService.availableLocales, { hour: '2-digit', minute: '2-digit' });
      }
    }
  }
  private clearValue() {
    this.value = '';
    this.date = '';
    this.time = '';
  }
  private getISOString() {
    let finalDate;
    if (this.date != '') {
      finalDate = new Date(this.date);
    } else {
      finalDate = new Date();
      finalDate.setUTCDate(0);
    }
    if (this.time != '') {
      const hours = this.time.split(':')[0];
      const minutes = this.time.split(':')[1];
      finalDate.setHours(parseInt(hours, 10), parseInt(minutes, 10));
    } else {
      finalDate.setUTCHours(0, 0);
    }
    return finalDate.toISOString();
  }
  private prepareAndEmitNewValue() {
    const isoString = this.getISOString();
    this.value = isoString;
    this.valueChange.emit(isoString);
    this.onChange(isoString);
    this.onTouched();
  }
}
