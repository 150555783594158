export class SmartCommunityConfiguration {
  constructor(private _uuid: string, private _organizationUUID: string, private _name: string, private _version: string) {}

  get uuid() {
    return this._uuid;
  }

  get organizationUUID() {
    return this._organizationUUID;
  }

  get name() {
    return this._name;
  }

  get version() {
    return this._version;
  }
}
