import { createAction, props } from '@ngrx/store';
import { Communication } from '../../interfaces';
import { Feedback } from '../../interfaces/Feedback';

export const setActiveCommunication = createAction('[Communications] Set active communication', props<{ activeCommunication: any }>());
export const setActiveCommunicationTab = createAction('[Communications] Set active communication tab', props<{ index: any }>());
/** Richiedere l'apertura del dettaglio di una comunicazione, può essere specificata direttamente la comunicazione da mostrare
 * o solo lo uuid (i dati verranno scaricati in maniera asincrona).
 * Una volta pronta la comunicazione, verrà impostata come "activeCommunication" */
export const openCommunicationDetail = createAction(
  '[Communications] Open detail communication request',
  props<{ uuid: string; communication?: Communication }>()
);
export const addCommunicationFeedback = createAction(
  '[Communications] Add communication feedback',
  props<{ key: string; feedback: Feedback }>()
);
