import { NgModule } from '@angular/core';
import { FieldsetComponent } from '../fieldset/fieldset.component';
import { ConfiguratorSharedModule, ToLocalizedValuePipe } from 'configurator-shared';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { RenderTrejsContainerComponent } from '../renderer/render-trejs-container/render-trejs-container.component';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  declarations: [FieldsetComponent, RenderTrejsContainerComponent],
  imports: [ConfiguratorSharedModule, MatOptionModule, MatSelectModule, MatInputModule, OverlayModule, ReactiveFormsModule],
  exports: [
    ConfiguratorSharedModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    OverlayModule,
    ReactiveFormsModule,
    FieldsetComponent,
  ],
  providers: [ToLocalizedValuePipe],
})
export class SharedModule {}
