export class LocalizableField {
  constructor(private localizedValueMap: Array<{ language: string; value: string }> = []) {}

  get languages(): Array<string> {
    return [...this.localizedValueMap].map(({ language }) => language);
  }

  static createWithLocalizedValue(localizedValue: { language: string; value: string | undefined }) {
    const { language, value } = localizedValue;
    return new LocalizableField([{ language, value: value ?? '' }]);
  }

  get locale(): string {
    return this.localizedValueMap?.[0]?.language;
  }

  getLocalizedValue(locale: string, defaultLocale?: string): string | undefined {
    let localizedField = this.localizedValueMap?.find((value) => value.language === locale);
    if (!localizedField && defaultLocale) {
      localizedField = this.localizedValueMap?.find((value) => value.language === defaultLocale);
    }
    return localizedField?.value;
  }

  areAllLocalizationWithValue(): boolean {
    const at_least_one_empty = !!this.localizedValueMap.find((element) => !element.value);
    return !at_least_one_empty;
  }

  addLocalizedValue(language: string, value: string): void {
    const existing = this.localizedValueMap.find((v) => v.language === language);
    if (existing) {
      existing.value = value;
    } else {
      this.localizedValueMap.push({ language, value });
    }
  }
}
