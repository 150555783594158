import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommunicationsState, COMMUNICATIONS_TAG } from './communications.state';

const CommunicationsSelector = createFeatureSelector<CommunicationsState>(COMMUNICATIONS_TAG);

export const getCommunications = createSelector(CommunicationsSelector, (state: CommunicationsState) => state?.communications);
export const getCommunicationsByFeature = (featureUUID: string) =>
  createSelector(CommunicationsSelector, (state: CommunicationsState) => state?.communications[featureUUID]);
export const getFeatureValues = createSelector(CommunicationsSelector, (state: CommunicationsState) => state?.featureValues);
export const getActiveCommunication = createSelector(CommunicationsSelector, (state: CommunicationsState) => state?.activeCommunication);
export const getActiveCommunicationTab = createSelector(
  CommunicationsSelector,
  (state: CommunicationsState) => state?.activeCommunicationTab
);
export const getFeedbackMap = createSelector(CommunicationsSelector, (state: CommunicationsState) => state?.feedbackMap);
export const getCommunicationFeedbacks = (communicationUUID: string) =>
  createSelector(CommunicationsSelector, (state: CommunicationsState) => state?.feedbackMap[communicationUUID]);
export const getActiveFeatureValues = createSelector(getCommunications, getFeatureValues, (commMap, featureValues) => {
  return featureValues.filter(({ uuid }) => commMap[uuid]?.length > 0);
});
export const isLoadingCommunications = createSelector(CommunicationsSelector, (state: CommunicationsState) => state.isLoading);
