import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { delay, map, tap } from 'rxjs';
import { logout, prelogout, setGlobalLoading } from '../../store';

@Injectable()
export class AppEffects {
  prelogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(prelogout),
      map(() => setGlobalLoading({ loading: true }))
    )
  );

  // sul logout rimanda alla pagina di login e toglie il loader
  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      tap(() => this.navCtrl.navigateRoot(['login'], { queryParams: { manual: true } })),
      delay(1000),
      map(() => setGlobalLoading({ loading: false }))
    )
  );

  constructor(private actions$: Actions, private navCtrl: NavController) {}
}
