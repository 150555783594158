import { ReactiveFormsModule } from '@angular/forms';
import { LoginFormComponent } from './login-form/login-form.component';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { AUTH_TAG, ConfiguratorSharedModule } from 'configurator-shared';
import { PrivacyListComponent } from './privacy-list/privacy-list.component';
import { LoggerModule } from 'ngx-logger';
import { EffectsModule } from '@ngrx/effects';
import { AuthReducer } from './store/auth.reducer';
import { AuthEffects } from './store/auth.effects';

@NgModule({
    declarations: [LoginFormComponent, PasswordRecoveryComponent, PrivacyListComponent],
    imports: [
        ReactiveFormsModule,
        ConfiguratorSharedModule,
        StoreModule.forFeature(AUTH_TAG, AuthReducer),
        EffectsModule.forFeature([AuthEffects]),
        LoggerModule,
    ],
    exports: [LoginFormComponent, PasswordRecoveryComponent, PrivacyListComponent],
    providers: [],
})
export class ConfiguratorAuthenticationModule {
    //TODO any
    static forRoot(providers: Provider[]): ModuleWithProviders<ConfiguratorAuthenticationModule> {
        return {
            ngModule: ConfiguratorAuthenticationModule,
            providers,
        };
    }
}
