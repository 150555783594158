import { LocalizableField } from './LocalizableField';

export class CommunicationCategory {
  static readonly UUID_FIELD_KEY = 'uuid';
  static readonly NAME_FIELD_KEY = 'name';
  static readonly PRIORITY_CATEGORY_FIELD_KEY = 'order';

  constructor(private _uuid: string, private _name: LocalizableField, private _priority: number) {}

  get uuid() {
    return this._uuid;
  }

  get name() {
    return this._name;
  }
  get priority() {
    return this._priority;
  }
}
