export class SortInfo {
  constructor(private _sortBy: string = '', private _sortDirection: string = '') {}

  get sortBy(): string {
    return this._sortBy;
  }

  set sortBy(value: string) {
    this._sortBy = value;
  }

  get sortDirection(): string {
    return this._sortDirection;
  }

  set sortDirection(value: string) {
    this._sortDirection = value;
  }

  get completeSortInfo() {
    if (this.sortDirection) return this.sortBy + '#' + this.sortDirection;

    return this.sortBy;
  }
}
